import React, { useEffect, useState } from "react";
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import API from "api/API";
import { useTranslation } from "react-i18next";
import TimelineComponent from "./TimelineComponent";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const Status = {
  NEW: 0,
  CONFIRMED: 1,
  TICKET: 2,
  CANCELED: 3,
  CLOSED: 4,
};

const VariableHistory = ({
  variableId,
  onClose,
  horizontal,
  onClick,
  fetchHistory,
  reset,
}) => {
  const [data, setData] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    API.getVariableHistoryByVariableId(
      (variableId ?? location.state?.id) || {}
    ).then((result) => {
      if (result.isSuccess) {
        setData(result.data);
      } else {
        console.error("failed fetch data");
      }
    });
  }, [variableId, fetchHistory]);

  return (
    <Box p={4} width={"100%"}>
      <Box
        bg="white"
        p={4}
        display="flex"
        flexDirection="column"
        position="relative"
      >
        <Heading size="md" pb="20px" textAlign="center">
          {data[0]?.name
            ? t("variable_history.title")
            : t("variable_history.no_history")}
          <br />
          {data[0]?.name ? t(`${data[0]?.name}`) : ""}
        </Heading>

        <Box
          flex="1"
          overflowY={horizontal ? "hidden" : "auto"}
          overflowX={horizontal ? "auto" : "hidden"}
          width={"100%"}
        >
          {Array.isArray(data) &&
            data?.length > 0 &&
            horizontal === undefined && <TimelineComponent logs={data} />}

          {(data?.length === 0 || !data) && <Text>{t("no_history")}</Text>}
        </Box>
      </Box>
    </Box>
  );
};

export default VariableHistory;
