/* eslint-disable */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import { Flex, Icon } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

export function SidebarLinks(props) {
  //   Chakra color mode
  let location = useLocation();

  const { routes, isOpen, sidebarWidth } = props;
  const { t } = useTranslation();

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    return routes.map((route, index) => {
      return (
        <NavLink key={index} to={route.path}>
          <Flex
            _hover={{
              background: "pcr.100",
              color: activeRoute(route.path.toLowerCase())
                ? "secondaryGray.900"
                : "secondaryGray.800",
            }}
            background={
              activeRoute(route.path.toLowerCase()) ? "pcr.200" : "transparent"
            }
            color={
              activeRoute(route.path.toLowerCase())
                ? "secondaryGray.900"
                : "secondaryGray.800"
            }
            fontWeight={
              activeRoute(route.path.toLowerCase()) ? "bold" : "semibold"
            }
            transition={"0.2s ease-in-out"}
            rounded={"md"}
            display="flex"
            alignItems="center"
            w={isOpen ? sidebarWidth : "100%"}
            p="5"
            cursor="pointer"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            <Icon
              sx={{
                minWidth: 0,
                mr: isOpen ? 3 : "auto",
                justifyContent: "center",
              }}
              boxSize={5}
            >
              {route.icon}
            </Icon>

            {isOpen ? t(route.name) : ""}
          </Flex>
        </NavLink>
      );
    });
  };
  return createLinks(routes);
}

export default SidebarLinks;