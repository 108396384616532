import React, { useState } from "react";
import {
  useBreakpointValue,
} from "@chakra-ui/react";
import SidebarMobile from "./components/SidebarMobile";
import SidebarStandard from "./components/SidebarStandard";

const sidebarWidth = "250px";

export default function Sidebar(props) {
  const { routes, onToggle, isOpen } = props;

  const isMobile = useBreakpointValue({ base: true, md: false });

  const toggleSidebar = () => {
    onToggle(!isOpen);
  };

  return (
    <>
      {isMobile ? (
        <SidebarMobile
          isOpen={isOpen}
          routes={routes}
          toggleSidebar={toggleSidebar}
          sidebarWidth={sidebarWidth}
        />
      ) : (
        <SidebarStandard
          isOpen={isOpen}
          routes={routes}
          toggleSidebar={toggleSidebar}
          sidebarWidth={sidebarWidth}
        />
      )}
    </>
  );
}
