import React, { useState } from "react";
import { Box, Flex, Text, Stack, Button } from "@chakra-ui/react";
import SliderPopup from "components/slider/SliderPopup";
import EventManage, { Status } from "./EventHistory/EventManage";
import { useTranslation } from "react-i18next";

const bgColor = "pcr.200";
const hoverColor = "pcr.100";

const MobileEventsTable = ({ data }) => {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (row) => {
    setSelectedRow(row);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setSelectedRow(null);
  };
  return (
    <Flex direction="column" padding={4} width="90%">
      {Array.isArray(data) ? (
        data.map((item, index) => (
          <Box
            key={index}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            padding={4}
            marginBottom={4}
            boxShadow="sm"
          >
            <Stack spacing={2}>
              <Flex justifyContent="space-between">
                <Text fontWeight="bold">{t("events_list.event_time")}:</Text>
                <Text>{item.eventTime}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontWeight="bold">{t("events_list.id")}:</Text>
                <Text>{item.deviceId}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontWeight="bold">{t("events_list.location")}:</Text>
                <Text>{item.location}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontWeight="bold">{t("events_list.status")}:</Text>
                <Text>{t(`event_form.${item.status}`)}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontWeight="bold">{t("events_list.description")}:</Text>
                <Text>{item.description}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Button
                  bgColor={bgColor}
                  _hover={{ bgColor: hoverColor }}
                  ml={"5px"}
                  textColor="black"
                  onClick={() => handleOpen(item)}
                >
                  {" "}
                  {item?.status === Status.CANCELED ||
                  item?.status === Status.CLOSED
                    ? t("events_list.history")
                    : t("events_list.manage_event")}
                </Button>
              </Flex>
            </Stack>
          </Box>
        ))
      ) : (
        <></>
      )}
      {selectedRow && (
        <SliderPopup isOpen={isOpen} onClose={handleClose}>
          <EventManage onClose={handleClose} eventID={selectedRow.id} />
        </SliderPopup>
      )}
    </Flex>
  );
};

export default MobileEventsTable;
