import React from "react";
import { Box, SimpleGrid, useBreakpointValue } from "@chakra-ui/react";
import CreateDevice from "../CreateDevice";
import DeviceHistory from "./DeviceHistory";

const UpdateDeviceLayout = ({ onClose, deviceData, deviceId }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box height="calc(100vh - 100px)">
      <SimpleGrid columns={isMobile ? 1 : 2} spacing={4} height="100%">
        <Box
          p={4}
          borderRadius="md"
          boxShadow="md"
          // overflowY={isMobile ? "auto" : "hidden"}
          overflowY="auto"
          height={isMobile ? "100%" : "100vh"}
        >
          <CreateDevice onClose={onClose} deviceData={deviceData} />
        </Box>
        <Box
          p={4}
          borderRadius="md"
          boxShadow="md"
          overflowY={"hidden"}
          height="100%"
        >
          <DeviceHistory onClose={onClose} deviceId={deviceId} />
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default UpdateDeviceLayout;
