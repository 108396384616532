import { Box, Flex, Stack, Circle, useBreakpointValue } from "@chakra-ui/react";
import Links from "components/sidebar/components/Links";
import React from "react";

function SidebarContent(props) {
  const { routes, isOpen, sidebarWidth } = props;
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex direction="column" alignItems={isOpen ? "flex-start" : "center"}>
      <Stack direction="column" mb="auto" mt="8px">
        {isMobile && isOpen ? (
          routes.map((route, index) => (
            <Circle
              key={index}
              size="50px"
              bg="blue.500"
              color="white"
              mb="12px"
              cursor="pointer"
              _hover={{ bg: "blue.600" }}
              onClick={() => {
                window.location.href = route.path;
              }}
            >
              {route.icon}
            </Circle>
          ))
        ) : (
          <Box>
            <Links
              routes={routes}
              isOpen={isOpen}
              sidebarWidth={sidebarWidth}
            />
          </Box>
        )}
      </Stack>
      <Box mt="60px" mb="40px" borderRadius="30px"></Box>
    </Flex>
  );
}

export default SidebarContent;
