import mqtt from "mqtt";

let client = null;
let isConnected = false;
const subscriptions = {};

export const connectMQTT = (brokerUrl, options = {}) => {
  if (!client) {
    client = mqtt.connect(brokerUrl, options);

    client.on("connect", () => {
      console.log("Connected to MQTT broker");
      isConnected = true;
      Object.keys(subscriptions).forEach((topic) => {
        subscribeToTopic(topic, subscriptions[topic]);
      });
    });

    client.on("error", (err) => {
      console.error("MQTT connection error:", err);
      isConnected = false;
    });

    client.on("close", () => {
      console.log("MQTT connection closed");
      isConnected = false;
    });
  }
};

export const subscribeToTopic = (topic, onMessageCallback) => {
  if (isConnected) {
    client.subscribe(topic, (err) => {
      if (err) {
        console.error(`Failed to subscribe to topic ${topic}:`, err);
      } else {
        console.log(`Subscribed to topic ${topic}`);
        subscriptions[topic] = onMessageCallback;
      }
    });

    client.on("message", (receivedTopic, message) => {
      if (receivedTopic === topic) {
        onMessageCallback(message.toString());
      }
    });
  } else {
    console.error(
      "MQTT client is not connected, adding to pending subscriptions"
    );
    subscriptions[topic] = onMessageCallback;
  }
};

export const unsubscribeFromTopic = (topic) => {
  if (isConnected && subscriptions[topic]) {
    client.unsubscribe(topic, (err) => {
      if (err) {
        console.error(`Failed to unsubscribe from topic ${topic}:`, err);
      } else {
        console.log(`Unsubscribed from topic ${topic}`);
        delete subscriptions[topic];
      }
    });
  } else {
    console.error("MQTT client is not connected or topic not subscribed");
  }
};

export const disconnectMQTT = () => {
  if (client) {
    client.end(() => {
      console.log("Disconnected from MQTT broker");
    });
    client = null;
    isConnected = false;
  }
};

export const cleanTopics = () => {
  Object.keys(subscriptions).forEach((key) => delete subscriptions[key]);
};

export const publishToTopic = (topic, message) => {
  if (isConnected) {
    client.publish(topic, message, (err) => {
      if (err) {
        console.error(`Failed to publish message to topic ${topic}:`, err);
      } else {
        console.log(`Message published to topic ${topic}`);
      }
    });
  } else {
    console.error("MQTT client is not connected, cannot publish message");
  }
};
