import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  Button,
  Flex,
  Table,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { usePagination, useSortBy, useTable, useFilters } from "react-table";
import Card from "components/card/Card";
import Pagination from "components/table/Pagination";
import { useTranslation } from "react-i18next";
import ColumnsButton from "components/filtersTable/ColumnsButton";
import FilterButton from "components/filtersTable/FilterButton";
import ExportButton from "components/filtersTable/ExportButton";
import TableContent from "components/filtersTable/TableContent";
import LoadingSpinner from "components/generic/LoadingSpinner";
import { formatDate } from "./TableContent";
import { parseISO, isBefore, isAfter, parse, isEqual } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useMeasuresStore } from "contexts/stores";

export default function FiltersTable({
  tableData = [],
  isFiltersOn,
  isMapDashboard,
  pageType,
  columnsList = [],
  toggleStates,
  onToggleChange,
  columnsVariables,
  pagination = undefined,
  applyFilter,
  statusOptions,
  hideColumnsOnLoad,
  rowClick,
  cardGrid,
  isInDrawer,
  navigateToPage,
  isVisible,
  selectOptions,
  onOptionChange,
}) {
  const { t } = useTranslation();
  const [columnsData, setColumnsData] = useState([]);
  const [tableDataString, setTableDataString] = useState([]);
  const [loading, setLoading] = useState(false);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const navigate = useNavigate();
  const { filters } = useMeasuresStore();

  // Stato per le colonne nascoste
  const [hiddenColumns, setHiddenColumns] = useState([]);

  const orFilter = (rows, columnId, filterValues) => {
    const filterValuesToLower = Array.isArray(filterValues)
      ? filterValues.map((val) => (val + "").toLowerCase())
      : [(filterValues + "").toLowerCase()];

    return rows.filter((row) => {
      const cellValue = (row.values[columnId] + "").toLowerCase();

      return filterValuesToLower.some((filterValue) => {
        return cellValue.includes(filterValue);
      });
    });
  };

  const dateRangeFilter = (rows, id, filterValues) => {
    const dateRanges = Array.isArray(filterValues)
      ? filterValues
      : [filterValues];

    return rows.filter((row) => {
      const cellValue = row.values[id];
      const date = parse(cellValue, "dd/MM/yyyy HH:mm", new Date());

      if (isNaN(date.getTime())) {
        return false;
      }

      return dateRanges.some((val) => {
        const fromDate = val.fromDate ? parseISO(val.fromDate) : null;
        const toDate = val.toDate ? parseISO(val.toDate) : null;

        const isAfterFrom = fromDate
          ? isEqual(date, fromDate) || isAfter(date, fromDate)
          : true;
        const isBeforeTo = toDate
          ? isEqual(date, toDate) || isBefore(date, toDate)
          : true;
        return isAfterFrom && isBeforeTo;
      });
    });
  };

  useEffect(() => {
    if (tableData?.length > 0) {
      let properties = Object.keys(tableData[0]);
      let cols = properties.map((key) => {
        return {
          id: key, // Aggiungere l'ID per mantenere la consistenza
          Header: key,
          accessor: key,
          type:
            columnsVariables?.find((col) => col?.accessor === key)?.type ||
            tableData[0][key],
          filter:
            columnsVariables?.find((col) => col?.accessor === key)?.type ===
            "date"
              ? dateRangeFilter
              : orFilter,
        };
      });
      setColumnsData(cols);
      let obj = tableData;
      for (let i = 0; i < obj.length; i++) {
        for (let key in obj[i]) {
          if (obj[i].hasOwnProperty(key)) {
            if (cols.find((col) => col.Header === key).type === "date") {
              obj[i][key] = formatDate(obj[i][key]);
            } else if (
              cols.find((col) => col.Header === key).type === "textList" &&
              Array.isArray(obj[i][key])
            ) {
              obj[i][key] = obj[i][key]?.map((opt) => opt?.name ?? opt);
            } else {
              obj[i][key] =
                obj[i][key] !== undefined && obj[i][key] !== null
                  ? obj[i][key]
                  : null;
            }
          }
        }
      }
      setTableDataString(obj);
    }
  }, [tableData, columnsVariables]);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableDataString, [tableDataString]);

  // Conteggio delle pagine controllate (solo quando la paginazione è fornita)
  const [controlledPageCount, setControlledPageCount] = useState(0);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    toggleHideColumn,
    state: { pageIndex, pageSize, globalFilter, sortBy },
    rows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: hiddenColumns, // Inizializza le colonne nascoste
      },
      manualPagination: !!pagination,
      manualGlobalFilter: !!pagination,
      manualSortBy: !!pagination,
      autoResetPage: false,
      autoResetHiddenColumns: false, // Evita il reset delle colonne nascoste
      ...(pagination
        ? {
            pageCount: controlledPageCount,
          }
        : {}),
    },
    useFilters,
    useSortBy,
    usePagination
  );

  // Mantieni traccia dell'inizializzazione di columnsData
  const columnsDataInitialized = useRef(false);

  // Fetch dei dati quando la paginazione è fornita (paginazione server-side)
  useEffect(() => {
    if (pagination) {
      const fetchData = async () => {
        const { data, totalItems } = await pagination.fetchPage(
          pageIndex,
          pageSize,
          globalFilter,
          sortBy,
          filters
        );

        // Processa i dati per assicurarsi che le proprietà esistano
        const processedData = data.map((item) =>
          Object.fromEntries(
            Object.entries(item).map(([key, value]) => [key, value ?? null])
          )
        );
        setTableDataString(processedData);

        // Imposta columnsData se non già impostato
        if (!columnsDataInitialized.current && data.length > 0) {
          const properties = Object.keys(data[0]);
          const cols = properties.map((key) => ({
            id: key, // Aggiungi l'ID qui
            Header: key,
            accessor: key,
            type:
              columnsVariables?.find((col) => col?.accessor === key)?.type ||
              data[0][key],
            filter:
              columnsVariables?.find((col) => col?.accessor === key)?.type ===
              "date"
                ? dateRangeFilter
                : orFilter,
          }));
          setColumnsData(cols);
          columnsDataInitialized.current = true;
        }

        // Aggiorna il conteggio delle pagine
        setControlledPageCount(Math.ceil(totalItems / pageSize));
      };
      fetchData();
    }
  }, [
    pagination,
    pageIndex,
    pageSize,
    globalFilter,
    sortBy,
    columnsVariables,
    filters,
  ]);

  // Gestisci i dati quando la paginazione non è fornita (paginazione client-side)
  useEffect(() => {
    if (!pagination && tableData?.length > 0) {
      const properties = Object.keys(tableData[0]);
      const cols = properties.map((key) => ({
        id: key, // Aggiungi l'ID qui
        Header: key,
        accessor: key,
        type:
          columnsVariables?.find((col) => col?.accessor === key)?.type ||
          tableData[0][key],
        filter:
          columnsVariables?.find((col) => col?.accessor === key)?.type ===
          "date"
            ? dateRangeFilter
            : orFilter,
      }));
      setColumnsData(cols);

      const processedData = tableData.map((item) =>
        Object.fromEntries(
          Object.entries(item).map(([key, value]) => [key, value ?? null])
        )
      );
      setTableDataString(processedData);
    }
  }, [pagination, tableData, columnsVariables]);

  // Aggiorna il filtro globale
  // useEffect(() => {
  //   setGlobalFilter(searchText || undefined);
  // }, [searchText, setGlobalFilter, tableData]);

  // Nascondi le colonne per la dashboard della mappa
  useEffect(() => {
    if (isMapDashboard) {
      headerGroups.forEach((headers) => {
        headers.headers.forEach((header) => {
          if (
            header.id !== "eventTime" &&
            header.id !== "action" &&
            header.id !== "description"
          )
            toggleHideColumn(header.id, true);
        });
      });
    }
  }, [toggleHideColumn, isMapDashboard, headerGroups]);

  useEffect(() => {
    const localStorageKey = `${pageType}-column-visibility`;
    if (hideColumnsOnLoad) {
      localStorage.setItem(localStorageKey, JSON.stringify(hideColumnsOnLoad));
    }
  }, []);

  // Gestisci il cambiamento delle colonne nascoste
  const handleToggleColumn = (columnId) => {
    setHiddenColumns((prevHiddenColumns) => {
      if (prevHiddenColumns.includes(columnId)) {
        return prevHiddenColumns.filter((id) => id !== columnId);
      } else {
        return [...prevHiddenColumns, columnId];
      }
    });
  };

  return (
    <Card
      direction="column"
      w={isMapDashboard ? "50% " : "100%"}
      px="0px"
      overflowX={"auto"}
    >
      {!isFiltersOn && (
        <Flex>
          {!cardGrid && (
            <ColumnsButton
              allColumns={allColumns}
              toggleHideColumn={toggleHideColumn}
              pageType={pageType}
              handleToggleColumn={handleToggleColumn}
              isInDrawer={isInDrawer}
            />
          )}
          <FilterButton
            allColumns={allColumns}
            tableData={tableData}
            pageType={pageType}
            applyFilter={applyFilter}
            statusOptions={statusOptions}
            isInDrawer={isInDrawer}
            dependencyArray={[
              tableDataString,
              allColumns,
              hideColumnsOnLoad,
              tableData,
            ]}
            isVisible={isVisible}
            paginated={pagination}
          />
          <ExportButton
            columns={columns}
            rows={rows}
            allColumns={allColumns}
            pageType={pageType}
          />

          {navigateToPage && (
            <Button
              bgColor={"pcr.200"}
              _hover={{ bgColor: "pcr.100" }}
              ml={1}
              onClick={() => navigate(navigateToPage)}
            >
              {t(`navigate.${navigateToPage}`)}
            </Button>
          )}
        </Flex>
      )}

      {loading ? (
        <Flex justifyContent="center" alignItems="center" height="200px">
          <LoadingSpinner />
        </Flex>
      ) : !cardGrid ? (
        <>
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
          >
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={column.id}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "12px", lg: "14px" }}
                        color="gray.400"
                      >
                        {pageType === undefined
                          ? t(`${column.Header}`)
                          : t(`${pageType}.${column.Header}`)}
                      </Flex>
                    </Th>
                  ))}

                  {columnsList.map(function (columList, index) {
                    return (
                      <Th key={index}>
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: "12px", lg: "14px" }}
                          color="gray.400"
                        >
                          {t(columList.Header)}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <TableContent
              onOptionChange={onOptionChange}
              selectOptions={selectOptions}
              rowClick={rowClick}
              page={page}
              prepareRow={prepareRow}
              textColor={textColor}
              columnsList={columnsList}
              pageType={pageType}
              toggleStates={toggleStates}
              onToggleChange={onToggleChange}
              {...getTableBodyProps()}
            />
          </Table>

          <Pagination
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageCount={pagination ? controlledPageCount : pageOptions.length}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />
        </>
      ) : (
        cardGrid.component(rows)
      )}
    </Card>
  );
}
