import { useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";


const useSignalR = (hubUrl) => {
  const [connection, setConnection] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl) 
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    setConnection(newConnection);

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, [hubUrl]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          console.log("Connessione SignalR stabilita.");


          connection.on("ReceiveNotification", (message) => {
            setMessages((prevMessages) => [...prevMessages, message]);
          });
        })
        .catch((error) =>
          console.error("Errore nella connessione SignalR: ", error)
        );
    }
  }, [connection]);


  const sendMessage = async (message) => {
    if (connection && connection.connectionStarted) {
      try {
        await connection.send("SendMessage", message);
      } catch (error) {
        console.error("Errore nell'invio del messaggio: ", error);
      }
    } else {
      console.error("La connessione non è attiva.");
    }
  };

  return { messages, sendMessage };
};

export default useSignalR;
