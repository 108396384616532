import React, { useEffect, useState } from "react";
import { Flex, Box, useBreakpointValue } from "@chakra-ui/react";

import ColumnSelect from "./filterComponents/ColumnSelect";
import FilterValueInput from "./filterComponents/FilterValueInput";
import ExtraFilter from "./filterComponents/ExtraFilter";
import FilterActions from "./filterComponents/FilterActions";
import { useTranslation } from "react-i18next";
import { useMeasuresStore } from "contexts/stores";

const PaginatedColumnFilter = ({
  columns,
  pageType,
  applyFilter,
  setIsFilterActive,
  statusOptions,
}) => {
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [extraFilters, setExtraFilters] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const { t } = useTranslation();
  const [currentFilter, setCurrentFilter] = useState("");
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { setFilters } = useMeasuresStore(); // Fetch function

  useEffect(() => {
    if (applyFilter && columns.length > 0) {
      const firstFilter = Array.isArray(applyFilter)
        ? applyFilter[0]
        : applyFilter;
      const column = columns.find((col) => col.id === firstFilter?.column);

      if (column) {
        setInputValue(firstFilter.value);
        setSelectedColumn(column);

        if (Array.isArray(applyFilter) && applyFilter.length > 1) {
          const remainingFilters = applyFilter.slice(1);
          const applyExtraFilters = remainingFilters[0]
            .map((filter) => {
              const column = columns.find((col) => col.id === filter?.column);
              if (
                column &&
                filter?.value !== undefined &&
                filter?.value !== null
              ) {
                return {
                  column: column,
                  value: filter.value,
                };
              }
              return null;
            })
            .filter((item) => item !== null);

          setExtraFilters(
            applyExtraFilters.length > 0 ? applyExtraFilters : []
          );
          applyFilters(
            { column: column, value: firstFilter.value },
            applyExtraFilters
          );
        } else {
          applyFilters(
            { column: column, value: firstFilter.value },
            extraFilters
          );
        }
      }
    }
  }, [applyFilter, columns.length]);

  const handleFilterChange = (value) => {
    setInputValue(value);
    applyFilters({ column: selectedColumn, value }, extraFilters);
  };

  const handleDateFilterChange = (value, dateType) => {
    if (dateType === "from") {
      setFromDate(value);
    } else if (dateType === "to") {
      setToDate(value);
    }
  };

  useEffect(() => {
    if (selectedColumn?.type === "date") {
      applyFilters(
        { column: selectedColumn, value: { fromDate, toDate } },
        extraFilters
      );
    }
  }, [fromDate, toDate, selectedColumn, extraFilters]);

  const handleDateFilterChangeExtra = (value, dateType, index) => {
    const newFilters = [...extraFilters];
    if (!newFilters[index].value) {
      newFilters[index].value = { fromDate: "", toDate: "" };
    }

    if (dateType === "from") {
      newFilters[index].value.fromDate = value;
    } else if (dateType === "to") {
      newFilters[index].value.toDate = value;
    }

    setExtraFilters(newFilters);
  };

  useEffect(() => {
    extraFilters.forEach((filter, index) => {
      if (selectedColumn?.type === "date") {
        applyFilters(
          { column: selectedColumn, value: { fromDate, toDate } },
          extraFilters
        );
      } else {
        applyFilters(
          { column: selectedColumn, value: inputValue },
          extraFilters
        );
      }
    });
  }, [extraFilters, inputValue, selectedColumn]);

  const handleColumnChange = (selectedOption) => {
    if (selectedColumn) selectedColumn.setFilter("");
    const column = columns.find((col) => col.id === selectedOption.value);
    setSelectedColumn(column);
    setInputValue("");
    setFromDate("");
    setToDate("");
    applyFilters({ column, value: "" }, extraFilters);
  };

  const handleFilterChangeExtra = (value, index) => {
    const newFilters = [...extraFilters];
    newFilters[index].value = value;
    setExtraFilters(newFilters);
    applyFilters({ column: selectedColumn, value: inputValue }, newFilters);
  };

  const handleColumnChangeExtra = (selectedOption, index) => {
    columns.forEach((col) => col.setFilter(""));
    const column = columns.find((col) => col.id === selectedOption.value);
    const newFilters = [...extraFilters];
    newFilters[index] = { column, value: "" };
    setExtraFilters(newFilters);
    applyFilters({ column: selectedColumn, value: inputValue }, newFilters);
  };

  const handleAddFilter = () =>
    setExtraFilters([...extraFilters, { column: null, value: "" }]);

  const handleRemoveFilter = (index) => {
    columns.forEach((col) => col.setFilter(""));
    setFilters((previousFilters) => {
      const updatedFilters = [...previousFilters];
      updatedFilters.splice(index, 1); // Rimuove il filtro specifico
      return updatedFilters;
    });
    const newFilters = [...extraFilters];
    newFilters.splice(index, 1);
    setExtraFilters(newFilters);
    applyFilters({ column: selectedColumn, value: inputValue }, newFilters);
  };

  const handleReset = () => {
    setIsFilterActive(false);
    setSelectedColumn(null);
    setInputValue("");
    setFilters([]);
    columns.forEach((col) => col.setFilter(""));
    setExtraFilters([]);
    setFromDate("");
    setToDate("");
    setCurrentFilter(null);
  };

  const applyFilters = (fixedFilter, extraFilters) => {
    const filters = [
      {
        column: fixedFilter.column?.id,
        value: fixedFilter.value,
      },
      ...extraFilters.map((filter) => ({
        column: filter.column?.id,
        value: filter.value,
      })),
    ];

    setFilters(filters);

    setIsFilterActive(true);
  };

  const booleanOptions = [
    { value: "true", label: "Enabled" },
    { value: "false", label: "Disabled" },
  ];

  const customStyles = {
    control: (base) => ({
      ...base,
      minWidth: "150px",
      maxWidth: "200px",
      backgroundColor: "#EEEEEE",
      borderColor: "black",
    }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <Box>
      <Flex direction="column" gap="2">
        <Flex direction={isMobile ? "column" : "row"} gap="2">
          <Flex direction={"column"} gap="1">
            {t("column_filter.select_column")}: {""}
            <ColumnSelect
              columns={columns}
              selectedColumn={selectedColumn}
              onChange={handleColumnChange}
              pageType={pageType}
              customStyles={customStyles}
            />
          </Flex>
          <Flex direction={"column"} gap="1">
            {t("column_filter.filter")}: {""}
            <FilterValueInput
              selectedColumn={selectedColumn}
              inputValue={inputValue}
              setInputValue={setInputValue}
              handleFilterChange={handleFilterChange}
              handleDateFilterChange={handleDateFilterChange}
              inputDateFromValue={fromDate}
              inputDateToValue={toDate}
              customStyles={customStyles}
              booleanOptions={booleanOptions}
              statusOptions={statusOptions}
            />
          </Flex>
        </Flex>
        {extraFilters.map((filter, index) => (
          <ExtraFilter
            key={index}
            filter={filter}
            index={index}
            columnOptions={columns.map((col) => ({
              value: col.id,
              label: t(`${pageType}.${col.Header}`),
            }))}
            handleColumnChangeExtra={handleColumnChangeExtra}
            handleFilterChangeExtra={handleFilterChangeExtra}
            handleDateFilterChangeExtra={(value, dateType) =>
              handleDateFilterChangeExtra(value, dateType, index)
            }
            inputDateFromValue={filter.value?.fromDate || ""}
            inputDateToValue={filter.value?.toDate || ""}
            handleRemoveFilter={handleRemoveFilter}
            booleanOptions={booleanOptions}
            statusOptions={statusOptions}
            customStyles={customStyles}
          />
        ))}
        <FilterActions
          handleAddFilter={handleAddFilter}
          handleReset={handleReset}
        />
      </Flex>
    </Box>
  );
};

export default PaginatedColumnFilter;
