import React, { useEffect, useState } from "react";
import { Box, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import API from "api/API";
import "react-phone-input-2/lib/style.css";
import { useOperatorsStore, useGroupsStore } from "contexts/stores";
import { enqueueSnackbar } from "notistack";
import FormInput from "./FormInput"; // Import the new FormInput component
import FormButtons from "components/slider/FormButtons";
import { isOperatorAvailable } from "utils/mapUser";

function CreateOperator({ onClose, operatorData }) {
  const { t } = useTranslation();
  const [readOnly, setReadOnly] = useState(operatorData ? true : false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: {
      password: "",
      email: operatorData?.email || "",
      username: operatorData?.username || "",
      firstName: operatorData?.firstName || "",
      lastName: operatorData?.lastName || "",
      location: operatorData?.location || "",
      number: operatorData?.number || "",
      availability: operatorData?.availability || isOperatorAvailable.RESTING,
      groups: operatorData?.groups
        ? operatorData.groups.map((group) => ({
            value: group,
            label: group,
          }))
        : [],
      isTicketManager: operatorData?.isTicketManager ?? false,
    },
  });

  const { groups, fetchGroups } = useGroupsStore();
  const { fetchOperators, operators } = useOperatorsStore();

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const groupOptions = groups?.map((group) => ({
    value: group.name,
    label: group.name,
  }));

  const onSubmit = (data) => {
    const dataFormatted = operatorData
      ? {
          ...operatorData,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          attributes: {
            number: [
              data.number === operatorData.number
                ? operatorData.number
                : "+" + data.number,
            ],
            location: [data.location],
            availability: ["" + isOperatorAvailable.RESTING],
            isTicketManager: [data.isTicketManager + ""], //string expected by backend
          },
          groups: data.groups.map((tag) => tag.label),
        }
      : {
          enabled: true,
          username: data.username,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          credentials: [
            { type: "password", value: data.password, temporary: true },
          ],
          attributes: {
            number: ["+" + data.number],
            location: [data.location],
            availability: ["" + isOperatorAvailable.RESTING],
            isTicketManager: [data.isTicketManager + ""], //string expected by backend
          },
          groups: data.groups.map((tag) => tag.label),
        };

    const apiCall = operatorData
      ? API.updateOperator(dataFormatted)
      : API.createOperator(dataFormatted);

    apiCall.then((result) => {
      if (result.isSuccess) {
        enqueueSnackbar(
          operatorData
            ? t("create_operator_form.edit_success")
            : t("create_operator_form.create_success"),
          { variant: "success" }
        );
        fetchOperators();
        onClose();
      } else {
        enqueueSnackbar(result.errorMsg || result.data, { variant: "error" });
      }
    });
  };

  const isValidNumber = (value) => {
    const regex = /^(?:\+39)?(?:\s*)?(3\d{2}|\d{2,4})(?:\s|-)?\d{6,8}$/;
    return regex.test(value);
  };

  const isValidEmail = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(value) || t("create_operator_form.invalid_email");
  };

  const isEmailConflict = async (email) => {
    try {
      if (operatorData) {
        const isConflict = operators.some(
          (op) => op.email === email && op.id !== operatorData.id
        );
        return isConflict
          ? t("create_operator_form.email_conflict_error")
          : true;
      } else {
        const isConflict = operators.some((op) => op.email === email);
        return isConflict
          ? t("create_operator_form.email_conflict_error")
          : true;
      }
    } catch (error) {
      enqueueSnackbar(t("create_operator_form.email_conflict_error"), {
        variant: "error",
      });
      return t("create_operator_form.email_conflict_error");
    }
  };

  const isUsernameConflict = async (username) => {
    try {
      if (operatorData) {
        const isConflict = operators.some(
          (op) => op.username === username && op.id !== operatorData.id
        );
        return isConflict
          ? t("create_operator_form.username_conflict_error")
          : true;
      } else {
        const isConflict = operators.some((op) => op.username === username);
        return isConflict
          ? t("create_operator_form.username_conflict_error")
          : true;
      }
    } catch (error) {
      enqueueSnackbar(t("create_operator_form.username_conflict_error"), {
        variant: "error",
      });
      return t("create_operator_form.username_conflict_error");
    }
  };

  return (
    <Box p={4}>
      <Box bg="white" p={4}>
        <Heading size="md" pb={"20px"} textAlign={"center"}>
          {operatorData
            ? t("create_operator_form.edit_operator")
            : t("create_operator_form.add_operator")}
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            name="username"
            placeholder={"Username"}
            register={register}
            errors={errors}
            validationRules={{
              required: t("create_operator_form.username_required"),
              validate: (value) =>
                isUsernameConflict(value) ||
                t("create_operator_form.email_invalid"),
            }}
            isDisabled={!!operatorData}
          />

          {operatorData === undefined && (
            <FormInput
              name="password"
              type="text"
              placeholder={t("create_operator_form.password")}
              register={register}
              errors={errors}
              validationRules={{
                required: t("create_operator_form.password_required"),
              }}
              isReadOnly={readOnly}
            />
          )}

          <FormInput
            name="email"
            placeholder={t("create_operator_form.email")}
            register={register}
            errors={errors}
            validationRules={{
              required: t("create_operator_form.email_required"),
              validate: (value) =>
                (isValidEmail(value) && isEmailConflict(value)) ||
                t("create_operator_form.email_invalid"),
            }}
            isReadOnly={readOnly}
          />

          <FormInput
            name="isTicketManager"
            placeholder={t("create_operator_form.isTicketManager")}
            register={register}
            errors={errors}
            control={control}
            isReadOnly={readOnly}
            isBoolean={true}
          />

          <FormInput
            name="firstName"
            placeholder={t("create_operator_form.name")}
            register={register}
            errors={errors}
            validationRules={{
              required: t("create_operator_form.name_required"),
            }}
            isReadOnly={readOnly}
          />

          <FormInput
            name="lastName"
            placeholder={t("create_operator_form.surname")}
            register={register}
            errors={errors}
            validationRules={{
              required: t("create_operator_form.surname_required"),
            }}
            isReadOnly={readOnly}
          />

          <FormInput
            name="number"
            placeholder={t("create_operator_form.number")}
            control={control}
            errors={errors}
            isPhoneInput={true}
            validationRules={{
              required: t("create_operator_form.number_required"),
              validate: (value) =>
                isValidNumber(value) ||
                t("create_operator_form.number_invalid"),
            }}
            isReadOnly={readOnly}
          />

          <FormInput
            name="location"
            placeholder={t("operators_list.location")}
            register={register}
            errors={errors}
            validationRules={{
              required: t("create_operator_form.location_required"),
            }}
            isReadOnly={readOnly}
          />

          <FormInput
            name="groups"
            placeholder={t("create_operator_form.groups")}
            control={control}
            errors={errors}
            isSelect={true}
            selectOptions={groupOptions}
            isReadOnly={readOnly}
          />

          <FormButtons
            onClose={onClose}
            isUpdateForm={!!operatorData}
            setReadOnly={setReadOnly}
            isReadOnly={readOnly}
            deleteAction={() => API.removeUser(operatorData.username)}
          />
        </form>
      </Box>
    </Box>
  );
}

export default CreateOperator;
