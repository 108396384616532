import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
} from "@chakra-ui/react";
import API from "api/API";
import FormButtons from "components/slider/FormButtons";
import { useVariableStore } from "contexts/stores";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";

const SeverityForm = ({ variableData, onClose }) => {
  const { t } = useTranslation();
  const { fetchVariable } = useVariableStore();
  const [readOnly, setReadOnly] = useState(true);

  const selectOptions = [
    {
      value: true,
      label: t(`variable_list.error`),
    },
    {
      value: false,
      label: t(`variable_list.silent`),
    },
  ];

  const onSubmit = (formData) => {
    const newVariable = {
      ...formData,
      isError: formData.isError.value,
    };

    API.updateVariable(newVariable).then((result) => {
      if (result.isSuccess) {
        fetchVariable();
        onClose();
        enqueueSnackbar(t("variable_form.success"), {
          variant: "success",
        });
      } else {
        console.error("failed get data");
        enqueueSnackbar(t("variable_form.error"), {
          variant: "error",
        });
      }
    });
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...variableData,
      isError: {
        value: variableData.isError,
        label: selectOptions.find(
          (option) => option.value === variableData.isError
        ).label,
      },
    },
  });

  return (
    <Box p={4}>
      <Heading size="md" pb={"20px"} textAlign={"center"}>
        {t("variable_form.title")}
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl p={"0px"} isInvalid={errors.tags}>
          <Grid
            templateColumns={{ base: "1fr", md: "150px 1fr" }}
            gap={1}
            mb={4}
            justifyContent={"center"}
            alignItems={"center"}
            alignContent={"center"}
          >
            <Flex alignItems="center">
              <FormLabel>{t("variable_form.severity")}:</FormLabel>
            </Flex>
            <Flex direction={"column"}>
              <Controller
                name="isError"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    isDisabled={readOnly}
                    {...field}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                    menuPlacement="bottom"
                    options={selectOptions}
                    onChange={(selected) => {
                      field.onChange(selected);
                    }}
                    placeholder={t(`variable_list.select_severity`)}
                    styles={{
                      control: (base) => ({
                        ...base,
                        minWidth: "150px",
                        maxWidth: "200px",
                        backgroundColor: readOnly ? "#EEEEEE" : "white",
                        borderColor: "black",
                        fontSize: "1.2em",
                      }),
                      menu: (base) => ({
                        ...base,
                        fontSize: "1.2em",
                        minWidth: "150px",
                        maxWidth: "200px",
                      }),
                    }}
                  />
                )}
              />
            </Flex>
          </Grid>
          <FormErrorMessage>
            {errors.tags && errors.tags.message}
          </FormErrorMessage>
        </FormControl>
        <FormButtons
          onClose={onClose}
          isUpdateForm={!!variableData}
          setReadOnly={setReadOnly}
          isReadOnly={readOnly}
        />
      </form>
    </Box>
  );
};

export default SeverityForm;
