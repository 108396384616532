import React, { useEffect } from "react";
import { Box, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import API from "api/API";
import { enqueueSnackbar } from "notistack";
import { useOperatorsStore } from "contexts/stores";
import { useGroupsStore } from "contexts/stores";
import FormButtons from "components/slider/FormButtons";
import FormInput from "views/operators/components/FormInput";

const permissionsOptions = [
  { value: "read", label: "Lettura" },
  { value: "write", label: "Lettura e scrittura" },
  { value: "forbidden", label: "Vietato" },
  { value: "inherited", label: "Vietato (ereditato)" },
];

function CreateGroup({ onClose, groupData }) {
  const { t } = useTranslation();
  const { fetchGroups } = useGroupsStore();
  const { operators, fetchOperators } = useOperatorsStore();

  const groupUsers = groupData?.name
    ? operators
        .filter((user) => user.groups.includes(groupData.name))
        .map((user) => ({
          value: user.email,
          label: `${user.firstName} ${user.lastName}`,
        }))
    : [];

  useEffect(() => {
    fetchOperators();
  }, [fetchOperators]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: {
      name: groupData?.name || "",
      users: groupUsers,
      permissions: groupData?.permissions || [],
    },
  });

  const filteredUsers = operators
    ?.filter(
      (user) => !groupUsers.some((groupUser) => groupUser.value === user.email)
    )
    .map((user) => ({
      value: user.email,
      label: `${user.firstName} ${user.lastName}`,
    }));

  const handleUserUpdate = (data, groupName) => {
    operators.forEach((user) => {
      // Check if the user has been selected for the current group
      const isInGroup = data.users.some(
        (userObj) => userObj.value === user.email
      );

      // Generate the updated list of groups
      const updatedGroups = isInGroup
        ? [...new Set([...user.groups, groupName])]
        : user.groups.filter((group) => group !== groupName);

      // Compare original groups with updated ones
      const groupsChanged =
        updatedGroups?.length !== user.groups?.length ||
        updatedGroups.some((group, index) => group !== user.groups[index]);

      if (groupsChanged) {
        const userUpdated = {
          ...user,
          groups: updatedGroups,
        };

        API.updateOperator(userUpdated).then((result) => {
          if (result.isSuccess) {
            enqueueSnackbar(t("create_operator_form.edit_success"), {
              variant: "success",
            });
            fetchOperators();
          } else {
            enqueueSnackbar(t("create_operator_form.edit_error"), {
              variant: "error",
            });
          }
        });
      }
    });
  };

  const onSubmit = (data) => {
    const groupRequest = { name: data.name };

    const apiRequest = groupData
      ? API.updateGroup(groupRequest)
      : API.createGroup(groupRequest);

    apiRequest.then((result) => {
      if (result.isSuccess) {
        enqueueSnackbar(
          groupData
            ? t("create_device_form.edit_success")
            : t("create_group_form.create_success"),
          { variant: "success" }
        );
        fetchGroups();
        handleUserUpdate(data, data.name);
        onClose();
      } else {
        enqueueSnackbar(
          groupData
            ? t("create_device_form.edit_error")
            : t("create_group_form.create_error"),
          { variant: "error" }
        );
        onClose();
      }
    });
  };

  return (
    <Box p={4}>
      <Box bg="white" p={4}>
        <Heading size="md" pb={"20px"} textAlign={"center"}>
          {groupData
            ? t("create_group_form.edit_group")
            : t("create_group_form.add_group")}
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            name="name"
            type="text"
            placeholder={t("create_operator_form.name")}
            register={register}
            errors={errors}
            validationRules={{
              required: t("create_operator_form.name_required"),
            }}
          />
          <FormInput
            name="users"
            placeholder={t("create_group_form.users")}
            errors={errors}
            isSelect={true}
            control={control}
            selectOptions={filteredUsers}
          />

          <FormInput
            name="permissions"
            placeholder={t("create_group_form.permissions")}
            // register={register}
            errors={errors}
            isSelect={true}
            control={control}
            selectOptions={permissionsOptions}
          />

          <FormButtons onClose={onClose} />
        </form>
      </Box>
    </Box>
  );
}

export default CreateGroup;
