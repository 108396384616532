import React, { useEffect, useState } from "react";
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import API from "api/API";
import { useTranslation } from "react-i18next";
import TimelineComponent from "./TimelineComponent";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import HorizontalTimeline from "./HorizontalTimeline";

export const Status = {
  NEW: 0,
  CONFIRMED: 1,
  TICKET: 2,
  CANCELED: 3,
  CLOSED: 4,
};

const DeviceHistory = ({
  deviceId,
  onClose,
  horizontal,
  onClick,
  fetchHistory,
  reset,
}) => {
  const [data, setData] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (horizontal) {
      API.getDeviceStatusHistoryByDeviceId(
        (deviceId ?? location.state?.id) || {}
      ).then((result) => {
        if (result.isSuccess) {
          setData(result.data);
        } else {
          console.error("failed fetch data");
        }
      });
    } else {
      API.getDeviceHistoryByDeviceId(
        (deviceId ?? location.state?.id) || {}
      ).then((result) => {
        if (result.isSuccess) {
          setData(result.data);
        } else {
          console.error("failed fetch data");
        }
      });
    }
  }, [deviceId, fetchHistory]);

  return (
    <Box p={4} width={"100%"}>
      <Box
        bg="white"
        p={4}
        display="flex"
        flexDirection="column"
        position="relative"
      >
        <Heading size="md" pb="20px" textAlign="center">
          {horizontal ? t("device_status_history") : t("device_history")}
          <br />
          {data[0]?.name}
        </Heading>

        <Box
          flex="1"
          overflowY={horizontal ? "hidden" : "auto"}
          overflowX={horizontal ? "auto" : "hidden"}
          width={"100%"}
        >
          {Array.isArray(data) &&
            data?.length > 0 &&
            horizontal === undefined && <TimelineComponent logs={data} />}
          {Array.isArray(data) && data?.length > 0 && horizontal && (
            <HorizontalTimeline logs={data} onClick={onClick} reset={reset} />
          )}
          {(data?.length === 0 || !data) && <Text>{t("no_history")}</Text>}
        </Box>
      </Box>
    </Box>
  );
};

export default DeviceHistory;
