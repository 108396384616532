import React, { createContext, useContext, useState, useEffect } from 'react';

const ToggleContext = createContext();
let defaultToggle = []

export const ToggleProvider = ({ children }) => {
  const [toggleStates, setToggleStates] = useState({});

  useEffect(() => {
    const savedToggleStates = JSON.parse(localStorage.getItem("toggleStates")) || {};
    if (!savedToggleStates || Object.keys(savedToggleStates).length === 0) {
      setToggleStates(defaultToggle); // Puoi modificare 'defaultToggle' con il tuo id
    } else {
      setToggleStates(savedToggleStates);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("toggleStates", JSON.stringify(toggleStates));
  }, [toggleStates]);

  const handleToggleChange = (id, value) => {
    setToggleStates((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <ToggleContext.Provider value={{ toggleStates, handleToggleChange }}>
      {children}
    </ToggleContext.Provider>
  );
};

export const useToggle = () => {
  const context = useContext(ToggleContext);
  if (context === undefined) {
    throw new Error("useToggle must be used within a ToggleProvider");
  }
  return context;
};
