/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// Chakra imports
import {
  Box,
  Collapse,
  Flex,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
// Assets

// Custom components
import React, { useEffect, useRef, useState } from "react";

import PieChart from "./components/PieChart";
import { useTranslation } from "react-i18next";
import MapComponent from "./components/DevicesMap";
import MobileEventsTable from "../events/components/MobileEventsTable";
import ToggleViewSwitch from "components/switch/ToggleViewSwitch";
import {
  useEventsStore,
  useOperatorsStore,
  useDevicesStore,
} from "contexts/stores";
import LoadingSpinner from "components/generic/LoadingSpinner";
import FiltersTable from "components/filtersTable/FiltersTable";
import EventManage from "views/events/components/EventHistory/EventManage";
import { columnsData } from "views/events/variables/columnsData";

export default function Dashboard() {
  const { t } = useTranslation();
  const dataSectionRef = useRef(null);
  const eventSectionRef = useRef(null);

  const [isMapVisible, setIsMapVisible] = useState(true);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { events, isEventsUpdating, fetchEvents } = useEventsStore();
  const { operators, isOperatorsUpdating, fetchOperators } =
    useOperatorsStore();
  const { devices, isDevicesUpdating, fetchDevices } = useDevicesStore();
  const [eventFilter, setEventFilter] = useState("");

  const statusOptions = [
    { value: 0, label: t(`events_list.status_list.0`) },
    { value: 1, label: t("events_list.status_list.1") },
    { value: 2, label: t("events_list.status_list.2") },
    { value: 3, label: t("events_list.status_list.3") },
    { value: 4, label: t("events_list.status_list.4") },
  ];

  const rowClickEvent = {
    page: "device_list",
    render: (handleClose, row) => (
      <EventManage onClose={handleClose} eventID={row?.original?.id} />
    ),
  };

  const getDevices = async () => {
    await fetchDevices();
  }

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  useEffect(() => {
    fetchOperators();
  }, [fetchOperators]);

  useEffect(() => {
    getDevices()
  }, []);

  useEffect(() => {
    if (dataSectionRef.current)
      dataSectionRef.current.scrollIntoView({ behavior: "smooth" });
  }, [dataSectionRef]);

  return isEventsUpdating || isOperatorsUpdating || isDevicesUpdating ? (
    <LoadingSpinner />
  ) : events?.length > 0 || operators?.length > 0 || devices?.length > 0 ? (
    <Box
      bgColor="white"
      padding="15px"
      shadow={"lg"}
      ref={isMapVisible ? dataSectionRef : null}
    >
      <Text
        align={"center"}
        fontSize={{ base: "2xl", md: "4xl" }}
        fontFamily={"sans-serif"}
      >
        {t("dashboard_title")}
      </Text>
      <ToggleViewSwitch
        isListTable={isMapVisible}
        setIsListTable={setIsMapVisible}
      />

      <Collapse in={!isMapVisible}>
        <Flex
          flexDirection={{
            base: "column",
            xl: "row",
          }}
          justifyContent="space-around"
          alignItems="center"
          width="100%"
          wrap="wrap"
        >
          <Flex width={{ base: "100%", xl: "45%" }} mb={{ base: 2, md: 5 }}>
            <PieChart devicesData={devices} />
          </Flex>
          <Flex width={{ base: "100%", xl: "45%" }} mb={{ base: 2, md: 5 }}>
            <PieChart operatorsData={operators} />
          </Flex>
        </Flex>
      </Collapse>

      {isMapVisible && (
        <Flex flexDirection={{ base: "column", xl: "row" }} alignItems="center">
          <Flex
            width={{ base: "100%", md: "70%" }}
            zIndex={0}
            mb={{ base: 2, md: 5 }}
          >
            <MapComponent
              devices={devices}
              setEventFilter={setEventFilter}
              dataSectionRef={eventSectionRef}
            />
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            width={isMobile ? "100%" : "50%"}
            wrap="wrap"
          >
            <Flex width="100%" mb={{ base: 2, md: 5 }}>
              <PieChart devicesData={devices} />
            </Flex>
            <Flex width="100%" mb={{ base: 2, md: 5 }}>
              <PieChart operatorsData={operators} />
            </Flex>
          </Flex>
        </Flex>
      )}
      {isMobile ? (
        <MobileEventsTable data={events} />
      ) : (
        <Box ref={eventSectionRef} overflowX={"auto"}>
          <FiltersTable
            tableData={events}
            columnsVariables={columnsData}
            rowClick={rowClickEvent}
            isDashboard={true}
            pageType={"events_list"}
            applyFilter={eventFilter}
            statusOptions={statusOptions}
            hideColumnsOnLoad={{
              id: false,
              eventTime: true,
              deviceId: true,
              deviceType: false,
              location: true,
              status: true,
              description: true,
              variable: true,
              variableDescription: false,
              variableLevel: true,
              counter: true,
            }}
          />
        </Box>
      )}
    </Box>
  ) : (
    <Box bgColor="white" padding="15px" shadow={"lg"}>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        w={"100%"}
        minH={"100vh"}
      >
        <Flex alignItems={"center"}>
          <Text pl="0.5rem" fontSize="4xl" as={"b"}>
            {t("empty_data")}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}
