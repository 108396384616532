import React from "react";
import { motion } from "framer-motion"; 
import {
  Box,
  useColorModeValue,
  VStack,
  Circle,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import Content from "components/sidebar/components/Content";

const SidebarMobile = ({routes, toggleSidebar, isOpen, sidebarWidth}) => {
  const circleBgOpen = useColorModeValue("blue.500", "red.400");
  const circleBgClose = useColorModeValue("red.400", "red.400");

  return (
    <Box pos="fixed" bottom="4" left="4" zIndex="1000">
      <Circle
        size="60px"
        bg={isOpen ? circleBgClose : circleBgOpen}
        color="white"
        onClick={toggleSidebar}
        cursor="pointer"
        boxShadow="md"
      >
        {isOpen ? <CloseIcon /> : <HamburgerIcon />}
      </Circle>

      {isOpen && (
        <VStack
          as={motion.div}
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
          pos="absolute"
          bottom="35px"
          right="0"
          spacing={4}
        >
          <Content
            routes={routes}
            isOpen={isOpen}
            sidebarWidth={sidebarWidth}
          />
        </VStack>
      )}
    </Box>
  );
};

export default SidebarMobile;
