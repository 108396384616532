import API from "api/API";
import { create } from "zustand";
import * as mqtt from "mqttManager/mqttManager";
import mapUserAttributes from "utils/mapUser";
import { config } from "config";
import { convertToString } from "utils/StringConverter";
import { isEqual } from "utils/isEqual";

export const useLoggedAccountStores = create((set) => ({
  roles: [],
  setRoles: (roles) => set({ roles: roles }),
}));

export const useKeycloak = create((set) => ({
  keycloak: [],
  setkeycloak: (keycloak) => set({ keycloak: keycloak }),
}));

export const useCallbacks = create((set, get) => ({
  callbacks: {},
  setCallbacks: (name, callback) => {
    set((state) => ({ callbacks: { ...state.callbacks, [name]: callback } }));
  },
}));

export const useMqtt = create((set, get) => ({
  isInitialized: false,
  init: (mqttConfig) => {
    const { isInitialized } = get();
    if (!isInitialized) {
      mqtt.connectMQTT(mqttConfig.url, mqttConfig);
      set((state) => ({ isInitialized: true }));
    }
  },
  clear: () => {
    mqtt.cleanTopics();
  },
  subscribe: (topic, callback) => {
    const { isInitialized } = get();
    if (isInitialized) {
      mqtt.subscribeToTopic(topic, callback);
    }
  },
  unsubscribe: (topic) => {
    mqtt.unsubscribeFromTopic(topic);
  },
  close: () => {
    mqtt.disconnectMQTT();
  },
}));

export const useUsernameStore = create((set) => ({
  username: "",
  setUsername: (user) => set({ username: user }),
}));

export const useDevicesStore = create((set, get) => ({
  // Deprecated
  allDevices: [],
  devices: [],
  isUpdating: false,
  get isDevicesUpdating() {
    return this.isUpdating;
  },

  // Deprecated
  fetchAllDevices: async () => {
    const { isUpdating } = get();
    if (!isUpdating) {
      set((state) => ({ isUpdating: true }));
      try {
        const result = await API.getDevices();
        if (result.isSuccess) {
          const { clear, subscribe } = useMqtt.getState();
          const { callbacks } = useCallbacks.getState();
          await clear();
          result.data.map(async (device) => {
            subscribe("device/" + device.name, callbacks.device);
          });
          set({ devices: result.data });
        } else {
          console.error("Failed to fetch events data");
        }
      } catch (error) {
        console.error("Error fetching operators:", error);
      }
      set((state) => ({ isUpdating: false }));
    }
  },

  fetchDevices: async () => {
    const { isUpdating } = get();
    if (!isUpdating) {
      set((state) => ({ isUpdating: true }));
      try {
        const result = await API.getRootDevices();
        if (result.isSuccess) {
          const { clear, subscribe } = useMqtt.getState();
          const { callbacks } = useCallbacks.getState();
          await clear();
          result.data.map(async (device) => {
            subscribe("device/" + device.name, callbacks.device);
          });
          set({ devices: result.data });
        } else {
          console.error("Failed to fetch events data");
        }
      } catch (error) {
        console.error("Error fetching operators:", error);
      }
      set((state) => ({ isUpdating: false }));
    }
  },
}));

export const translationsStore = create((set) => ({
  t: () => {},
  setTFunction: (t) => set({ t: t }),
}));

export const useOperatorsStore = create((set, get) => ({
  operators: [],
  isUpdating: false,
  get isOperatorsUpdating() {
    return this.isUpdating;
  },
  pollingOperatorsInterval: null,

  setPollingOperatorsInterval: (timeout = config.timers.fetchDataPolling) => {
    const { fetchOperators } = get();
    set({ pollingOperatorsInterval: setInterval(fetchOperators, timeout) });
  },

  clearPollingOperatorsInterval: () => {
    const { pollingOperatorsInterval } = get();
    clearInterval(pollingOperatorsInterval);
  },

  fetchOperators: async () => {
    const { isUpdating, operators } = get();
    if (!isUpdating) {
      set((state) => ({ isUpdating: true }));
      try {
        const result = await API.getAllOperators();
        if (result.isSuccess) {
          var mappedUserData = mapUserAttributes(
            result.data,
            translationsStore.getState().t
          );
          if (!isEqual(mappedUserData, operators)) {
            set({ operators: mappedUserData });
          }
        } else {
          console.error("Failed to fetch events data");
          set({ operators: [] });
        }
      } catch (error) {
        console.error("Error fetching operators:", error);
        set({ operators: [] });
      }
      set((state) => ({ isUpdating: false }));
    }
  },
}));

export const useGroupsStore = create((set, get) => ({
  groups: [],
  isUpdating: false,

  pollingGroupsInterval: null,

  setPollingGroupsInterval: (timeout = config.timers.fetchDataPolling) => {
    const { fetchGroups } = get();
    set({ pollingGroupsInterval: setInterval(fetchGroups, timeout) });
  },

  clearPollingGroupsInterval: () => {
    const { pollingGroupsInterval } = get();
    clearInterval(pollingGroupsInterval);
  },

  fetchGroups: async () => {
    const { isUpdating } = get();
    if (!isUpdating) {
      set((state) => ({ isUpdating: true }));
      try {
        const result = await API.getAllGroups();
        if (result.isSuccess) {
          set({ groups: result.data });
        } else {
          console.error("Failed to fetch events data");
        }
      } catch (error) {
        console.error("Error fetching operators:", error);
      }
      set((state) => ({ isUpdating: false }));
    }
  },
}));

export const useEventsStore = create((set, get) => ({
  events: [],
  isUpdating: false,
  isInDrawer: false,
  setIsInDrawer: (status) => set({ isInDrawer: status }),
  get isEventsUpdating() {
    return this.isUpdating;
  },

  pollingEventsInterval: null,

  setPollingEventsInterval: (timeout = config.timers.fetchDataPolling) => {
    const { fetchEvents } = get();
    set({ pollingEventsInterval: setInterval(fetchEvents, timeout) });
  },

  clearPollingEventsInterval: () => {
    const { pollingEventsInterval } = get();
    clearInterval(pollingEventsInterval);
  },

  fetchEvents: async () => {
    const fetchDevices = useDevicesStore.getState().fetchDevices;
    const { isInDrawer } = get();
    const { isUpdating } = get();
    if (!isUpdating) {
      set((state) => ({ isUpdating: true }));
      try {
        const result = await API.getAllEvents();
        if (result.isSuccess) {
          set({ events: result.data });
        } else {
          console.error("Failed to fetch events data");
        }
      } catch (error) {
        console.error("Error fetching operators:", error);
      }
      set((state) => ({ isUpdating: false }));
      if (isInDrawer) {
        fetchDevices();
      }
    }
  },
}));

export const useMeasuresStore = create((set, get) => ({
  measures: [],
  isUpdating: false,
  measuresCount: 0,
  filters: [],
  setFilters: (newFilters) => set({ filters: newFilters }),

  fetchMeasuresCount: async (globalFilter) => {
    try {
      const result = await API.getMeasuresCount(globalFilter);
      if (result.isSuccess) {
        set({ measuresCount: result.data });
      } else {
        console.error("Failed to fetch measures count");
      }
    } catch (error) {
      console.error("Error fetching measures count:", error);
    }
  },

  fetchMeasurePage: async (
    pageIndex,
    pageSize,
    globalFilter,
    sortBy,
    filters
  ) => {
    try {
      const offset = pageIndex * pageSize;
      const limit = pageSize;

      // Fetch measures for the current page
      const result = await API.getMeasuresPaginated(
        offset,
        limit,
        globalFilter,
        sortBy,
        filters
      );

      // Fetch the total count separately
      const countResult = await API.getMeasuresCount(globalFilter);

      if (result.isSuccess && countResult.isSuccess) {
        const data = result.data.data.map((m) => {
          if (m.enumerative) {
            m.measure.value = m.enumerative.kName;
          }
          if (m.type?.usertype) {
            m.measure.type = m.type?.usertype;
          }
          m.measure.event = "-";
          let stringMeasure = {};
          for (let key in m.measure) {
            stringMeasure[key] = convertToString(m.measure[key]);
          }
          return stringMeasure;
        });

        let totalItems = 0;
        if (filters) {
          totalItems = result.data.totalCount;
        } else totalItems = countResult.data;

        return {
          data,
          totalItems,
        };
      } else {
        console.error("Failed to fetch measures data or total count");
        return { data: [], totalItems: 0 };
      }
    } catch (error) {
      console.error("Error fetching measures:", error);
      return { data: [], totalItems: 0 };
    }
  },

  pollingMeasuresInterval: {
    pollingMeasuresCount: null,
  },

  setPollingMeasuresInterval: (timeout = config.timers.fetchDataPolling) => {
    const { fetchMeasuresCount } = get();
    set({
      pollingMeasuresInterval: {
        pollingMeasuresCount: setInterval(() => fetchMeasuresCount(), timeout),
      },
    });
  },

  clearPollingMeasuresInterval: () => {
    const { pollingMeasuresInterval } = get();
    clearInterval(pollingMeasuresInterval.pollingMeasuresCount);
  },
}));

export const useVariableStore = create((set, get) => ({
  variable: [],
  isUpdating: false,
  get isVariableUpdating() {
    return this.isUpdating;
  },
  fetchVariable: async () => {
    const { isUpdating } = get();
    if (!isUpdating) {
      set((state) => ({ isUpdating: true }));
      try {
        const result = await API.getAllVariable();
        if (result.isSuccess) {
          set({ variable: result.data });
        } else {
          console.error("Failed to fetch events data");
        }
      } catch (error) {
        console.error("Error fetching operators:", error);
      }
      set((state) => ({ isUpdating: false }));
    }
  },
}));
