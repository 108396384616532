/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Collapse, Flex, Text } from "@chakra-ui/react";
// Custom components

import React, { useEffect, useState } from "react";
import API from "../../api/API";
import { useGroupsStore } from "contexts/stores";
import PermissionsList from "./components/PermissionsList";
import InteractiveTable from "./components/PermissionsTable";
import { useOperatorsStore } from "contexts/stores";
import { useTranslation } from "react-i18next";
import ToggleViewSwitch from "components/switch/ToggleViewSwitch";
import LoadingSpinner from "components/generic/LoadingSpinner";

export default function PermissionsPage() {
  const [isListTable, setIsListTable] = useState(true);
  const { groups, isUpdating, fetchGroups } = useGroupsStore();
  const { operators, fetchOperators } = useOperatorsStore();
  const { t } = useTranslation();

  useEffect(() => {
    fetchOperators();
  }, [fetchOperators]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  return isUpdating && (!groups || groups.length === 0) ? (
    <LoadingSpinner />
  ) : (
    <>
      {groups?.length > 0 && operators?.length > 0 ? (
        <Box bgColor="white" padding="15px" shadow={"lg"}>
          <Flex>
            <ToggleViewSwitch
              isListTable={isListTable}
              setIsListTable={setIsListTable}
            />
          </Flex>
          <Collapse in={isListTable}>
            <PermissionsList
              groupsData={[...groups]}
              usersData={[...operators]}
            />
          </Collapse>

          <Collapse in={!isListTable}>
            {" "}
            <InteractiveTable
              groupsData={[...groups]}
              usersData={[...operators]}
            />
          </Collapse>
        </Box>
      ) : (
        <Box bgColor="white" padding="15px" shadow={"lg"}>
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            w={"100%"}
            minH={"100vh"}
          >
            <Flex alignItems={"center"}>
              <Text pl="0.5rem" fontSize="4xl" as={"b"}>
                {t("empty_data")}
              </Text>
            </Flex>
          </Flex>
        </Box>
      )}
    </>
  );
}
