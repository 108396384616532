// DeviceCardGrid.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Icon,
  Tooltip,
  Flex,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import SliderPopup from "components/slider/SliderPopup";
import UpdateDeviceLayout from "./DeviceHistory/UpdateDeviceLayout";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useTranslation } from "react-i18next";
import EventsPage from "views/events";
import { ToggleProvider } from "components/externalComponents/ToggleContext";
import DeviceHistory from "./DeviceHistory/DeviceHistory";
import { useEventsStore } from "contexts/stores";

export const DevIsAlive = {
  OK: 0,
  WARNING: 1,
  ERROR: 2,
};

const cardColors = {
  [DevIsAlive.OK]: "green.500",
  [DevIsAlive.WARNING]: "yellow",
  [DevIsAlive.ERROR]: "red.500",
};

const cardTextColors = {
  [DevIsAlive.OK]: "black",
  [DevIsAlive.WARNING]: "black",
  [DevIsAlive.ERROR]: "black",
};

const search = (text, data) => {
  if (!text) return data;

  return data.filter((device) => {
    return (
      device.name.toLowerCase().includes(text.toLowerCase()) ||
      device.location.toLowerCase().includes(text.toLowerCase()) ||
      device.version.toLowerCase().includes(text.toLowerCase()) ||
      device.lastModificationDateTime
        .toLowerCase()
        .includes(text.toLowerCase()) ||
      device.tags.some((tag) =>
        tag.name.toLowerCase().includes(text.toLowerCase())
      )
    );
  });
};

function DeviceCardGrid({ data, searchText, deleteDevice = (deviceId) => {} }) {
  const [filteredData, setFilteredData] = useState(
    data.map((r) => {
      return r.original;
    })
  );
  const { t } = useTranslation();
  const [eventFilter, setEventFilter] = useState([]);
  const events = useEventsStore();

  const [isModalOpen, setIsModalOpen] = useState(
    new Array(data.length).fill(false)
  );
  const [isModalOpen2, setIsModalOpen2] = useState(
    new Array(data.length).fill(false)
  );
  const [selectedDeviceIndex, setSelectedDeviceIndex] = useState(null);

  const handleDeviceSelect = (index) => {
    setSelectedDeviceIndex(index);
    setIsModalOpen((prev) =>
      prev.map((isOpen, i) => (i === index ? true : isOpen))
    );
  };
  const handleDeviceSelect2 = (index) => {
    setSelectedDeviceIndex(index);
    setIsModalOpen2((prev) =>
      prev.map((isOpen, i) => (i === index ? true : isOpen))
    );
  };

  const handleCloseModal = () => {
    setIsModalOpen((prev) =>
      prev.map((isOpen, i) => (i === selectedDeviceIndex ? false : isOpen))
    );
    setSelectedDeviceIndex(null);
  };
  const handleCloseModal2 = () => {
    setEventFilter([]);
    setIsModalOpen2((prev) =>
      prev.map((isOpen, i) => (i === selectedDeviceIndex ? false : isOpen))
    );
    setSelectedDeviceIndex(null);
  };

  const handleClick = (eventId) => {
    if (Array.isArray(eventId) && eventId?.length > 0) {
      setEventFilter(
        eventId.map((id) => {
          return {
            column: "id",
            value: id,
          };
        })
      );
    } else {
      setEventFilter({
        column: "id",
        value: eventId,
      });
    }
  };

  useEffect(() => {
    setFilteredData(
      data.map((r) => {
        return r.original;
      })
    );
  }, [data]);

  useEffect(() => {
    setIsModalOpen(new Array(filteredData.length).fill(false));
    setIsModalOpen2(new Array(filteredData.length).fill(false));
  }, [filteredData.length]);

  return (
    <Grid
      templateColumns="repeat(auto-fill, minmax(120px, 1fr))"
      gap={4}
      m={"20px"}
    >
      {filteredData.map((device, index) => (
        <GridItem key={index}>
          <Box
            key={index}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            bg={cardColors[device?.alive]}
            p={"5px"}
            textAlign="center"
            boxShadow="md"
            border="1px"
            width={"120px"}
            height={"120px"}
            onClick={() => {
              handleDeviceSelect2(index);
            }}
            cursor="pointer"
          >
            <Text
              fontSize="lg"
              fontWeight="bold"
              color={cardTextColors[device?.alive]}
            >
              {device?.name}
            </Text>
            <Text color={cardTextColors[device?.alive]}>
              {device?.location}
            </Text>
            <Text color={cardTextColors[device?.alive]} pt={"10px"}>
              {device?.type}
            </Text>
            <Flex pt={"10px"} justifyContent={"space-between"}>
              <Tooltip
                label={
                  Array.isArray(device?.tags)
                    ? device?.tags
                        .map((tag) =>
                          typeof tag === "object" ? tag.name : tag
                        )
                        .join(", ")
                    : device?.tags.toString()
                }
              >
                <Icon color={cardTextColors[device?.alive]}>
                  <SellOutlinedIcon />
                </Icon>
              </Tooltip>
              <Tooltip label={t("details")}>
                <Icon
                  color={cardTextColors[device?.alive]}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeviceSelect(index);
                  }}
                >
                  <SearchOutlinedIcon />
                </Icon>
              </Tooltip>
            </Flex>
            <SliderPopup
              key={index}
              isOpen={isModalOpen[index]}
              onClose={handleCloseModal}
            >
              <UpdateDeviceLayout
                onClose={handleCloseModal}
                deviceData={device}
                deviceId={device?.id}
              />
            </SliderPopup>

            <SliderPopup
              key={index + " 2"}
              isOpen={isModalOpen2[index]}
              onClose={handleCloseModal2}
            >
              <Flex height={"30vh"} width={"100%"} justifyContent={"center"}>
                <DeviceHistory
                  deviceId={device?.id}
                  horizontal={true}
                  onClick={handleClick}
                  fetchHistory={events}
                  reset={() => setEventFilter([])}
                />
              </Flex>
              <ToggleProvider>
                <EventsPage
                  applyFilter={[
                    {
                      column: "deviceId",
                      value: device?.name,
                    },
                    eventFilter,
                  ]}
                  isInDrawer={true}
                />
              </ToggleProvider>
            </SliderPopup>
          </Box>
        </GridItem>
      ))}
    </Grid>
  );
}

export default DeviceCardGrid;
