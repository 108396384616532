import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationsIT from "./translations/it.json";
import translationsEN from "./translations/en.json";

const resources = {
  it: translationsIT,
  en: translationsEN,
};

i18n.use(initReactI18next).init({
  resources,
  lng: "it", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
