import React from "react";
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

export function SearchBar(props) {
  const { t } = useTranslation();
  // Pass the computed styles into the `__css` prop
  const {
    variant,
    background,
    children,
    placeholder,
    borderRadius,
    setSearchText,
    ...rest
  } = props;
  // Chakra Color Mode
  const searchIconColor = useColorModeValue("gray.700", "white");
  const inputBg = useColorModeValue("white", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");
  return (
    <InputGroup
      w={{ base: "100%", md: "300px" }}
      {...rest}
      size="lg"
      fontSize="xl"
    >
      <Input
        border="2px"
        variant="search"
        fontSize="lg"
        bg={background ? background : inputBg}
        color={inputText}
        _placeholder={{ color: "gray.400", fontSize: "25px" }}
        borderRadius={borderRadius ? borderRadius : "30px"}
        placeholder={placeholder ? placeholder : t("search")}
        size="lg"
        onChange={(e) => setSearchText(e.target.value)}
        _focus={{ borderColor: "pcr.200" }}
      />
      <InputRightElement
        children={
          <IconButton
            bg="inherit"
            borderRadius="inherit"
            _hover={{}}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
            icon={<SearchIcon color={searchIconColor} w="30px" h="20px" />}
            disabled={true}
          ></IconButton>
        }
      />
    </InputGroup>
  );
}
