import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Icon,
  Flex,
  Tooltip,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import NotifyOperator from "./NotifyOperator";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import SliderPopup from "components/slider/SliderPopup";
import { isOperatorAvailable } from "utils/mapUser";
import { useOperatorsStore } from "contexts/stores";
import UpdateOperatorLayout from "../operatorHistory/UpdateOperatorLayout";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import { t } from "i18next";

const cardColors = {
  [isOperatorAvailable.ONSHIFT]: "green.500",
  [isOperatorAvailable.AVAILABLE]: "orange.500",
  [isOperatorAvailable.RESTING]: "red.500",
  [isOperatorAvailable.UNAVAILABLE]: "gray",
};

const cardTextColors = {
  [isOperatorAvailable.ONSHIFT]: "black",
  [isOperatorAvailable.RESTING]: "black",
  [isOperatorAvailable.AVAILABLE]: "black",
  [isOperatorAvailable.UNAVAILABLE]: "black",
};

const search = (text, data) => {
  if (!text) return data;

  return data.filter((operator) => {
    return (
      operator.firstName?.toLowerCase().includes(text.toLowerCase()) ||
      operator.location?.toLowerCase().includes(text.toLowerCase()) ||
      operator.lastName?.toLowerCase().includes(text.toLowerCase()) ||
      operator.number?.toLowerCase().includes(text.toLowerCase()) ||
      operator.groups?.some((group) =>
        group.toLowerCase().includes(text.toLowerCase())
      )
    );
  });
};

function OperatorCardGrid({ data, searchText }) {
  const { operators } = useOperatorsStore();
  const [filteredData, setFilteredData] = useState(
    data.map((r) => {
      return r.original;
    })
  );

  const [isModalOpen, setIsModalOpen] = useState(
    new Array(operators?.length).fill(false)
  );
  const [isModalNotificationOpen, setIsModalNotificationOpen] = useState(
    new Array(operators?.length).fill(false)
  );
  const [selectedOperatorIndex, setSelectedOperatorIndex] = useState(null);

  const handleOperatorSelect = (index) => {
    setSelectedOperatorIndex(index);
    setIsModalOpen((prev) =>
      prev.map((isOpen, i) => (i === index ? true : isOpen))
    );
  };

  const handleCloseModal = () => {
    setIsModalOpen((prev) =>
      prev.map((isOpen, i) => (i === selectedOperatorIndex ? false : isOpen))
    );
    setSelectedOperatorIndex(null);
  };

  const handleNotificationSelect = (index, event) => {
    event.stopPropagation();
    setSelectedOperatorIndex(index);
    setIsModalNotificationOpen((prev) =>
      prev.map((isOpen, i) => (i === index ? true : isOpen))
    );
  };

  const handleCloseNotificationModal = () => {
    setIsModalNotificationOpen((prev) =>
      prev.map((isOpen, i) => (i === selectedOperatorIndex ? false : isOpen))
    );
    setSelectedOperatorIndex(null);
  };

  useEffect(() => {
    setFilteredData(
      data.map((r) => {
        return r.original;
      })
    );
  }, [data]);

  useEffect(() => {
    setIsModalOpen(new Array(filteredData?.length).fill(false));
  }, [filteredData?.length]);

  return (
    <Grid
      templateColumns="repeat(auto-fill, minmax(120px, 1fr))"
      gap={6}
      m={{ base: "10px", md: "20px" }}
    >
      {filteredData?.map((operator, index) => (
        <GridItem key={index}>
          <Box
            key={index}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            bg={cardColors[operator.availability]}
            p={"10px"}
            textAlign="center"
            boxShadow="md"
            border="1px"
            borderColor={"blue.500"}
            width={"140px"}
            height={"140px"}
            onClick={() => {
              handleOperatorSelect(index);
            }}
            cursor="pointer"
          >
            <Text
              fontSize="lg"
              fontWeight="bold"
              color={cardTextColors[operator.availability]}
            >
              {operator.firstName}
            </Text>
            <Text
              fontSize="lg"
              fontWeight="bold"
              color={cardTextColors[operator.availability]}
            >
              {operator.lastName}
            </Text>
            <Text color={cardTextColors[operator.availability]}>
              {operator.location}
            </Text>
            <Text
              color={cardTextColors[operator.availability]}
              fontSize={"12px"}
            >
              {operator.number}
            </Text>

            <Flex pt={"10px"} justifyContent={"space-between"}>
              <Tooltip
                label={
                  Array.isArray(operator.groups)
                    ? operator.groups.join(", ")
                    : operator.groups.toString()
                }
              >
                <Icon color={cardTextColors[operator.availability]}>
                  <SellOutlinedIcon />
                </Icon>
              </Tooltip>
              {operator.isTicketManager && (
                <Tooltip label={t("operators_list.isTicketManager")}>
                  <Icon color={cardTextColors[operator.availability]}>
                    <ConfirmationNumberOutlinedIcon />
                  </Icon>
                </Tooltip>
              )}
              <Tooltip label={t("notify")}>
                <Icon
                  color={cardTextColors[operator.availability]}
                  onClick={(e) => {
                    handleNotificationSelect(index, e);
                  }}
                >
                  <MessageOutlinedIcon />
                </Icon>
              </Tooltip>
            </Flex>
          </Box>
          <SliderPopup isOpen={isModalOpen[index]} onClose={handleCloseModal}>
            <UpdateOperatorLayout
              onClose={handleCloseModal}
              operatorData={filteredData[selectedOperatorIndex]}
            />
          </SliderPopup>

          <SliderPopup
            isOpen={isModalNotificationOpen[index]}
            onClose={handleCloseNotificationModal}
          >
            <NotifyOperator
              operatorData={operator}
              onClose={handleCloseNotificationModal}
            />
          </SliderPopup>
        </GridItem>
      ))}
    </Grid>
  );
}

export default OperatorCardGrid;
