import { Flex, useColorModeValue } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import React from "react";

export default function NotFound() {
  const color = useColorModeValue("gray.400");
  return (
    <Flex w="100%" minH="80vh" justifyContent={"center"} alignItems={"center"}>
      <Text color={color} as={"b"} fontSize={"6xl"}>
        404
      </Text>
    </Flex>
  );
}
