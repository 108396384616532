import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import KeycloakLogin from "keycloakLogin";
import useSignalR from "utils/UseSignalR";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEventsStore } from "contexts/stores";
import { mqttConfig } from "mqttManager/config";
import { useMqtt } from "contexts/stores";

import i18n from "./i18n";
import { useCallbacks } from "contexts/stores";
import API from "api/API";
import { config } from "config";
import { useTranslation } from "react-i18next";
import { translationsStore } from "contexts/stores";
import { useOperatorsStore } from "contexts/stores";
import { useMeasuresStore } from "contexts/stores";

const App = () => {
  const [keepAliveInterval, setKeepaliveInterval] = useState(null);
  const { signalRMessages } = useSignalR(process.env.REACT_APP_NOTIFICATIONHUB);
  const [mqttMessages, setMqttMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const { fetchEvents } = useEventsStore();
  const {
    fetchMeasuresCount,
    setPollingMeasuresInterval,
    clearPollingMeasuresInterval,
  } = useMeasuresStore();
  const {
    fetchOperators,
    setPollingOperatorsInterval,
    clearPollingOperatorsInterval,
  } = useOperatorsStore();
  const { t } = useTranslation();

  // const { init } = useMqtt();
  const { setCallbacks } = useCallbacks();
  const { setTFunction } = translationsStore();

  useEffect(() => {
    setTFunction(t);
  }, [setTFunction, t]);

  useEffect(() => {
    fetchOperators();
    setPollingOperatorsInterval();
    return () => {
      clearPollingOperatorsInterval();
    };
  }, [
    fetchOperators,
    setPollingOperatorsInterval,
    clearPollingOperatorsInterval,
  ]);

  useEffect(() => {
    fetchMeasuresCount();
    setPollingMeasuresInterval();
    return () => {
      clearPollingMeasuresInterval();
    };
  }, [
    fetchMeasuresCount,
    setPollingMeasuresInterval,
    clearPollingMeasuresInterval,
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      API.sendKeepalive();
    }, config.timers.keepAlive);
    API.sendKeepalive();
    setKeepaliveInterval(intervalId);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (signalRMessages && signalRMessages.length > 0) {
      var mes = [...messages, signalRMessages[signalRMessages.length - 1]];
      setMessages(mes);
    }
  }, [signalRMessages]);

  useEffect(() => {
    if (mqttMessages && mqttMessages.length > 0) {
      var mes = [...messages, mqttMessages[mqttMessages.length - 1]];
      setMessages(mes);
    }
  }, [mqttMessages]);

  useEffect(() => {
    setCallbacks("device", (message) => {
      var mes = [...messages, message];
      setMqttMessages(mes);
    });
    // init(mqttConfig);
  }, [/*init,*/ setCallbacks, setMqttMessages]);

  useEffect(() => {
    if (Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        if (permission !== "granted") {
          console.warn("Permission for notifications was denied.");
        }
      });
    }

    if (messages && messages.length > 0) {
      const mess = JSON.parse(messages[messages.length - 1]);
      if (mess.type === "event") {
        toast(`${mess.device}: ${mess.description}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onOpen: () => {
            const sound = new Audio("/notification1.wav");
            sound.play();
          },
        });

        if (Notification.permission === "granted") {
          new Notification(mess.device, {
            body: mess.description,
            icon: "/favicon.ico",
          });
        }

        fetchEvents();
      }
    }
  }, [messages, fetchEvents]);

  return (
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <KeycloakLogin tab="home" />
        <ToastContainer />
      </React.StrictMode>
    </ChakraProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
