import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Flex,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import API from "api/API";
import { enqueueSnackbar } from "notistack";
import { useOperatorsStore } from "contexts/stores";
import PermissionsBox from "./PermissionsBox";

export default function PermissionsTable({ groupsData, usersData }) {
  const { t } = useTranslation();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const { updateOperators } = useOperatorsStore((state) => ({
    updateOperators: state.updateOperators,
  }));

  const handleClick = (user, group) => {
    let updatedUser = user;
    if (user.groups.includes(group.name)) {
      const updatedGroups = [...user.groups];
      updatedGroups.splice(user.groups.indexOf(group.name), 1);
      updatedUser = {
        ...user,
        groups: updatedGroups,
      };
    } else {
      updatedUser = {
        ...user,
        groups: [...user.groups, group.name],
      };
    }
    API.updateOperator(updatedUser).then((result) => {
      if (result.isSuccess) {
        enqueueSnackbar(t("create_operator_form.edit_success"), {
          variant: "success",
        });
        updateOperators();
      } else {
        enqueueSnackbar(t("create_operator_form.edit_error"), {
          variant: "error",
        });
      }
    });
  };

  return (
    <Box overflowX="auto">
      <Table variant="simple" mt={"30px"}>
        <Thead>
          <Tr>
            <Th border={"1px"} borderTop={"0px"} borderLeft={"0px"}></Th>
            {groupsData.map((group, index) => (
              <Th
                key={index}
                border={"1px"}
                borderTop={"0px"}
                borderRight={index === groupsData.length - 1 ? "0px" : "1px"}
                bg={
                  selectedGroup && selectedGroup.name === group.name
                    ? "pcr.200"
                    : "white"
                }
                onClick={() => {
                  if (selectedGroup?.name === group.name)
                    setSelectedGroup(null);
                  else setSelectedGroup(group);
                }}
                cursor="pointer"
              >
                {group.name}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {usersData.map((user, uIndex) => (
            <Tr key={uIndex}>
              <Td
                border={"1px"}
                borderLeft={"0px"}
                width={"5%"}
                onClick={() => {
                  if (selectedUser?.email === user.email) setSelectedUser(null);
                  else setSelectedUser(user);
                }}
                bg={
                  selectedUser && selectedUser.email === user.email
                    ? "pcr.200"
                    : "white"
                }
                cursor="pointer"
              >
                {user.firstName} {user.lastName}
              </Td>
              {groupsData.map((group, gIndex) => (
                <Td
                  key={gIndex}
                  border={"1px"}
                  borderRight={gIndex === groupsData.length - 1 ? "0px" : "1px"}
                  width={"5%"}
                  p={"0px"}
                >
                  <Button
                    width={"100%"}
                    onClick={() => {
                      handleClick(user, group);
                    }}
                  >
                    {user.groups.includes(group.name) ? (
                      <CheckCircleOutlinedIcon />
                    ) : (
                      <></>
                    )}
                  </Button>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Flex
        flexDirection={{
          base: "column",
          xl: "row",
        }}
        w="100%"
        h="100%"
        mt={"30px"}
        mb="20px"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <PermissionsBox
          selected={selectedGroup}
          isUnderTable={true}
          isGroup={true}
        />

        <PermissionsBox
          selected={selectedUser}
          isUnderTable={true}
          isUser={true}
        />
      </Flex>
    </Box>
  );
}
