import { CloseIcon } from "@chakra-ui/icons";
import { Flex, IconButton, Input, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";

const ExtraFilter = ({
  filter,
  index,
  columnOptions,
  handleColumnChangeExtra,
  handleFilterChangeExtra,
  handleRemoveFilter,
  booleanOptions,
  statusOptions,
  customStyles,
  handleDateFilterChangeExtra,
  inputDateFromValue,
  inputDateToValue,
}) => {
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Flex
      key={index}
      direction={isMobile ? "column" : "row"}
      gap="2"
      mt="10px"
      alignItems={isMobile ? "flex-start" : "center"}
    >
      <Flex direction="column" gap="1">
        {t("column_filter.select_column")}: {""}
        <ReactSelect
          menuPlacement="bottom"
          options={columnOptions}
          value={columnOptions.find(
            (option) => option.value === filter?.column?.id
          )}
          onChange={(option) => handleColumnChangeExtra(option, index)}
          placeholder={t("column_filter.select_column")}
          styles={customStyles}
        />
      </Flex>
      <Flex direction="column" gap="1">
        {t("column_filter.filter")}: {""}
        <Flex direction={isMobile ? "row" : "unset"} gap="1">
          {filter.column && typeof filter.column.type === "boolean" ? (
            <ReactSelect
              options={booleanOptions}
              onChange={(option) =>
                handleFilterChangeExtra(option.value, index)
              }
              value={
                filter.value
                  ? {
                      value: filter.value,
                      label:
                        filter.value === "true"
                          ? t("column_filter.value_enabled")
                          : t("column_filter.value_no_enabled"),
                    }
                  : null
              }
              placeholder={t("column_filter.filter")}
              styles={customStyles}
            />
          ) : filter.column && filter.column.type === "status" ? (
            <ReactSelect
              options={statusOptions}
              onChange={(option) =>
                handleFilterChangeExtra(option.value, index)
              }
              value={
                statusOptions.find((opt) => opt.value === filter.value) || null
              }
              placeholder={t("column_filter.select_status")}
              styles={customStyles}
            />
          ) : filter.column && filter.column.type === "date" ? (
            <Flex maxW={"180px"} direction={"column"}>
              {t("date.from")}:{" "}
              <Input
                type={"datetime-local"}
                value={inputDateFromValue}
                focusBorderColor="black"
                backgroundColor={"#EEEEEE"}
                placeholder={t("column_filter.filter")}
                onChange={(e) =>
                  handleDateFilterChangeExtra(e.target.value, "from")
                }
                width={"100%"}
                maxWidth={"200px"}
                mb={1}
              />
              {t("date.to")}:{" "}
              <Input
                type={"datetime-local"}
                value={inputDateToValue}
                focusBorderColor="black"
                backgroundColor={"#EEEEEE"}
                placeholder={t("column_filter.filter")}
                onChange={(e) =>
                  handleDateFilterChangeExtra(e.target.value, "to")
                }
                width={"100%"}
                maxWidth={"200px"}
              />
            </Flex>
          ) : (
            <Input
              value={filter.value}
              focusBorderColor="black"
              backgroundColor={"#EEEEEE"}
              placeholder={t("column_filter.filter")}
              onChange={(e) => handleFilterChangeExtra(e.target.value, index)}
              width={"100%"}
              maxWidth={"200px"}
            />
          )}
          <IconButton
            aria-label="Remove filter"
            icon={<CloseIcon />}
            onClick={() => handleRemoveFilter(index)}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ExtraFilter;
