import React from "react";
import { useKeycloak } from "contexts/stores";

const getAuthToken = () => localStorage.getItem("jwt_auth");

const refreshTokenIfNeeded = async () => {
  const { keycloak } = useKeycloak.getState();

  if (keycloak.isTokenExpired()) {
    const currentTime = Math.floor(Date.now() / 1000);
    if (keycloak.refreshTokenParsed.exp < currentTime) {
      keycloak.logout();
      throw new Error("Refresh token expired, logging out.");
    } else {
      if (keycloak.refreshToken) {
        const refreshed = await keycloak.updateToken();
        if (!refreshed) {
          keycloak.logout();
          throw new Error("Failed to refresh token, logging out.");
        } else {
          localStorage.setItem("jwt_auth", keycloak.token);
        }
      }
    }
  }
};

const request = async (method, endpoint, data = null) => {
  try {
    await refreshTokenIfNeeded();

    const token = getAuthToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let options = {
      method,
      headers,
      credentials: "include",
    };

    if (data) {
      if (method === "GET") {
        endpoint += "?" + new URLSearchParams(data).toString();
      } else {
        headers["Content-Type"] = "application/json";
        options.body = JSON.stringify(data);
      }
    }

    const response = await fetch(endpoint, options);

    if (response.status === 401) {
      const { keycloak } = useKeycloak.getState();
      if (keycloak.isTokenExpired()) {
        keycloak.logout();
        throw new Error("Token expired, logging out.");
      } else {
        return { isSuccess: false, data: undefined, errorMsg: "401" };
      }
    }

    const contentType = response.headers.get("content-type");
    let respData;
    if (contentType && contentType.includes("application/json")) {
      respData = await response.json();
    } else if (contentType && contentType.includes("text/plain")) {
      respData = await response.text();
    }

    return {
      isSuccess: response.ok,
      data: respData,
    };
  } catch (error) {
    console.error(`Error in ${method} request to ${endpoint}:`, error);
    return {
      isSuccess: false,
      data: undefined,
      errorMsg: error.message,
    };
  }
};

export const get = (endpoint, data) => request("GET", endpoint, data);
export const post = (endpoint, data) => request("POST", endpoint, data);
export const put = (endpoint, data) => request("PUT", endpoint, data);
export const del = (endpoint) => request("DELETE", endpoint);
