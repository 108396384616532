import React from "react";
import { Box, SimpleGrid, useBreakpointValue } from "@chakra-ui/react";
import CreateOperator from "../components/CreateOperator";
import OperatorHistory from "./OperatorHistory";

const UpdateOperatorLayout = ({ onClose, operatorData }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box height="calc(100vh - 100px)">
      <SimpleGrid columns={isMobile ? 1 : 2} spacing={4} height="100%">
        <Box
          p={4}
          borderRadius="md"
          boxShadow="md"
          // overflowY="hidden"
          overflowY="auto"
          height={isMobile ? "100%" : "100vh"}
        >
          <CreateOperator onClose={onClose} operatorData={operatorData} />
        </Box>
        <Box
          p={4}
          borderRadius="md"
          boxShadow="md"
          overflowY="hidden"
          height="100%"
        >
          <OperatorHistory
            onClose={onClose}
            operatorUsername={operatorData?.username}
          />
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default UpdateOperatorLayout;
