import React, { useState, useCallback, useEffect } from "react";
import { Flex, Box, Switch, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const ColumnVisibilityToggle = ({ allColumns, toggleHideColumn, pageType }) => {
  const { t } = useTranslation();

  // Chiave per local storage, differenziata per pagina
  const localStorageKey = `${pageType}-column-visibility`;

  // Funzione per caricare lo stato dal localStorage
  const loadInitialState = () => {
    const savedState = localStorage.getItem(localStorageKey);
    if (savedState) {
      return JSON.parse(savedState);
    } else {
      // Stato iniziale: visibilità delle colonne basato su isHidden
      return allColumns.reduce((acc, column) => {
        acc[column.id] =  column.defaultVisible !== undefined ? column.defaultVisible : true; // usa defaultVisible o visibile di default
        return acc;
      }, {});
    }
  };

  // Usa lo stato iniziale dal localStorage
  const [columnsState, setColumnsState] = useState(loadInitialState);

  // Effetto per sincronizzare lo stato locale con la visibilità delle colonne all'avvio
  useEffect(() => {
    allColumns.forEach((column) => {
      if (columnsState[column.id] !== column.isVisible) {
        toggleHideColumn(column.id, !columnsState[column.id]);
      }
    });
  }, [columnsState, allColumns, toggleHideColumn]);

  // Effetto per salvare lo stato nel Local Storage quando cambia
  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(columnsState));
  }, [columnsState, localStorageKey]);

  // Funzione per gestire il toggle della colonna
  const handleToggle = useCallback(
    (id) => {
      setColumnsState((prevState) => {
        const updatedState = { ...prevState, [id]: !prevState[id] };
        toggleHideColumn(id, prevState[id]); // Mostra o nascondi la colonna in base al nuovo stato
        return updatedState;
      });
    },
    [toggleHideColumn]
  );

  return (
    <Box p="20px" border="2px" m={"10px"}>
      <Flex direction="column">
        {allColumns.map((column) => (
          <Flex key={column.id} pt="5px">
            <Switch
              isChecked={columnsState[column.id]} // Verifica lo stato dal localStorage o stato locale
              onChange={() => handleToggle(column.id)}
            />
            <Text pl="5px">{pageType === undefined? t(`${column.Header}`) : t(`${pageType}.${column.Header}`)}</Text>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

export default ColumnVisibilityToggle;
