import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const inputBgColor = "#EEEEEE";
const permissionsOptions = [
  { value: "read", label: "Lettura" },
  { value: "write", label: "Lettura e scrittura" },
  { value: "forbidden", label: "Vietato" },
  { value: "inherited", label: "Vietato (ereditato)" },
];

export default function PermissionsBox({
  selected,
  isUnderTable,
  isGroup,
  isUser,
}) {
  const { t } = useTranslation();

  const getText = () => {
    if (selected === null) {
      if (isGroup) {
        return `${t("permissions_box.group_select")} ${selected?.name || ""}`;
      }
      if (isUser) {
        return `${t("permissions_box.user_select")} ${
          selected?.firstName || ""
        } ${selected?.lastName || ""}`;
      }
    } else {
      if (isGroup) {
        return `${t("permissions_box.group")} ${selected?.name || ""}`;
      }
      if (isUser) {
        return `${t("permissions_box.user")} ${selected?.firstName || ""} ${
          selected?.lastName || ""
        }`;
      }
    }
    return "";
  };

  return (
    <Box
      borderWidth="2px"
      border="1px"
      borderRadius="md"
      overflow="hidden"
      w={
        isUnderTable
          ? { base: "100%", sm: "75%", md: "75%", xl: "25%" }
          : { base: "100%", md: "60%", xl: "60%" }
      }
      minH="200px"
      overflowY="auto"
      p={"20px"}
      mr={isUnderTable ? { base: "0px", xl: "30px" } : ""}
      mb={isUnderTable ? "30px" : ""}
    >
      <Text fontSize={"24px"} align={"center"}>
        {getText()}
      </Text>
      <Text>Permesso 1</Text>
      <Select
        isDisabled={selected === null}
        options={permissionsOptions}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "black",
          },
        })}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: inputBgColor,
          }),
        }}
        menuPortalTarget={document.body}
        menuPlacement="top"
        placeholder={t("permissions_box.permission_select")}
      />
      <Text>Permesso 2</Text>
      <Select
        isDisabled={selected === null}
        options={permissionsOptions}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "black",
          },
        })}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: inputBgColor,
          }),
        }}
        menuPortalTarget={document.body}
        menuPlacement="top"
        placeholder={t("permissions_box.permission_select")}
      />
    </Box>
  );
}
