import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Box, Button, Heading } from "@chakra-ui/react";
import API from "api/API";
import { useTranslation } from "react-i18next";
import FormButtons from "components/slider/FormButtons";
import TimelineComponent from "./TimelineComponent";
import FormInput from "views/operators/components/FormInput";
import ReactSelect from "react-select";
import { enqueueSnackbar } from "notistack";
import { useEventsStore } from "contexts/stores";

export const Status = {
  NEW: 0,
  CONFIRMED: 1,
  TICKET: 2,
  CANCELED: 3,
  CLOSED: 4,
};

const EventManage = ({ eventID, onClose }) => {
  const [data, setData] = useState({});
  const [readOnly, setReadOnly] = useState(true);
  const { t } = useTranslation();
  const fetchEvents = useEventsStore((state) => state.fetchEvents);
  const historyOnly =
    data[0]?.status === Status.CLOSED || data[0]?.status === Status.CANCELED;

  const statusOption = [
    { label: t(`event_form.${0}`), value: Status.NEW, color: "#E0F7FA" },
    { label: t(`event_form.${1}`), value: Status.CONFIRMED, color: "#2196F3" },
    { label: t(`event_form.${2}`), value: Status.TICKET, color: "#ffd00d" },
    { label: t(`event_form.${3}`), value: Status.CANCELED, color: "#F44336" },
    { label: t(`event_form.${4}`), value: Status.CLOSED, color: "#4CAF50" },
  ];

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: data[0],
  });

  const watchData = watch();

  const onSubmit = (formData) => {
    const result = {
      status: formData.status.value ?? formData.status,
      id: formData.id,
      notes: formData.notes,
    };

    if ((formData.status.value ?? formData.status) === data[0].status) {
      onClose();
      enqueueSnackbar(t("event_form.info"), {
        variant: "info",
      });
    } else {
      API.updateEventHistory(result).then((response) => {
        if (response.isSuccess) {
          onClose();
          enqueueSnackbar(t("event_form.success"), {
            variant: "success",
          });
          fetchEvents();
        } else {
          enqueueSnackbar(t("event_form.error"), {
            variant: "error",
          });
        }
      });
    }
  };

  useEffect(() => {
    API.getEventHistoryById(eventID).then((result) => {
      if (result.isSuccess) {
        setData(result.data);
        reset(result.data[0]);
      } else {
        console.error("failed fetch data");
      }
    });
  }, [eventID, reset]);

  const getNextStatusOptions = () => {
    switch (data[0]?.status) {
      case Status.NEW:
        return statusOption.filter(
          (option) =>
            option.value === Status.CONFIRMED ||
            option.value === Status.CANCELED
        );
      case Status.CONFIRMED:
        return statusOption.filter(
          (option) =>
            option.value === Status.CANCELED ||
            option.value === Status.CLOSED ||
            option.value === Status.TICKET
        );
      case Status.TICKET:
        return statusOption.filter((option) => option.value === Status.CLOSED);
      case Status.CANCELED:
      case Status.CLOSED:
        return [];
      default:
        return statusOption;
    }
  };

  return (
    <Box p={4}>
      <Box bg="white" p={4} height="100vh">
        {!historyOnly && (
          <Heading size="md" pb={"20px"} textAlign={"center"}>
            {t("event_form.manage_event")}
          </Heading>
        )}

        {!historyOnly && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name={"status"}
              control={control}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  placeholder={t(`event_form.status`)}
                  options={getNextStatusOptions()}
                  menuPlacement="auto"
                  value={statusOption.find((o) => o.value === field.value)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                    },
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  })}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      marginBottom: 8,
                      backgroundColor: "#EEEEEE",
                    }),
                  }}
                  formatOptionLabel={(option) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          display: "inline-block",
                          width: 10,
                          height: 10,
                          borderRadius: "50%",
                          backgroundColor: option.color,
                          marginRight: 10,
                        }}
                      />
                      {option.label}
                    </div>
                  )}
                  onChange={(selected) => field.onChange(selected)}
                  isDisabled={readOnly}
                />
              )}
            />
            <FormInput
              name="notes"
              control={control}
              placeholder={t(`event_form.notes`)}
              errors={errors}
              isTextArea={true}
              isReadOnly={readOnly}
            />
            <FormButtons
              onClose={onClose}
              isUpdateForm={!!data[0]}
              setReadOnly={setReadOnly}
              isReadOnly={readOnly}
              disableSave={
                (watchData.status?.value ?? watchData.status) ===
                data[0]?.status
              }
            />
          </form>
        )}

        <Heading size="md" pb={"20px"} textAlign={"center"}>
          {t("event_form.history")}
        </Heading>

        {Array.isArray(data) && data.length > 0 && (
          <TimelineComponent events={data} />
        )}
      </Box>
    </Box>
  );
};

export default EventManage;
