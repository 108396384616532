import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";

const ColumnSelect = ({
  columns,
  selectedColumn,
  onChange,
  pageType,
  customStyles,
}) => {
  const { t } = useTranslation();
  const columnOptions = useMemo(
    () =>
      columns.map((column) => ({
        value: column.id,
        label: t(`${pageType}.${column.Header}`),
      })),
    [columns, t, pageType]
  );

  return (
    <ReactSelect
      menuPlacement="bottom"
      options={columnOptions}
      onChange={onChange}
      value={
        selectedColumn
          ? {
              value: selectedColumn.id,
              label: t(`${pageType}.${selectedColumn.Header}`),
            }
          : null
      }
      placeholder={t("column_filter.select_column")}
      styles={customStyles}
    />
  );
};

export default ColumnSelect;
