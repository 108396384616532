/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Collapse,
  Flex,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
// Custom components

import React, { useEffect, useState } from "react";
import { columnsDataDevices } from "views/devices/variables/columnsData";
import DeviceCardGrid from "./components/DeviceCardGrid";
import { useDevicesStore } from "contexts/stores";
import { useTranslation } from "react-i18next";
import ToggleViewSwitch from "components/switch/ToggleViewSwitch";
import LoadingSpinner from "components/generic/LoadingSpinner";
import FiltersTable from "components/filtersTable/FiltersTable";
import UpdateDeviceLayout from "./components/DeviceHistory/UpdateDeviceLayout";
import API from "api/API";
import { ToggleProvider } from "components/externalComponents/ToggleContext";
import EventsPage from "views/events";
import { useEventsStore } from "contexts/stores";
import DeviceHistory from "./components/DeviceHistory/DeviceHistory";

export default function Devicepage() {
  const [isListTable, setIsListTable] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [dataWithCoordinates, setDataWithCoordinates] = useState([]);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { devices, isUpdating, fetchDevices } = useDevicesStore();
  const events = useEventsStore();

  const { t } = useTranslation();

  const statusOptions = [
    { value: 0, label: t(`device_list.status_list.0`) },
    { value: 1, label: t("device_list.status_list.1") },
    { value: 2, label: t("device_list.status_list.2") },
  ];

  const deleteDevice = async (id) => {
    let isSure = window.confirm(t("confirm_delete_device"));
    if (isSure) {
      await API.removeDevice(id);
      fetchDevices();
    }
  };

  const [eventFilter, setEventFilter] = useState([]);

  const handleClick = (eventId) => {
    if (Array.isArray(eventId) && eventId?.length > 0) {
      setEventFilter(
        eventId.map((id) => {
          return {
            column: "id",
            value: id,
          };
        })
      );
    } else {
      setEventFilter({
        column: "id",
        value: eventId,
      });
    }
  };

  const rowClick = {
    page: "device_list",
    render: (handleClose, row) => (
      <>
        <Flex height={"30vh"} width={"100%"} justifyContent={"center"}>
          <DeviceHistory
            deviceId={row?.original?.id}
            horizontal={true}
            onClick={handleClick}
            fetchHistory={events}
            reset={() => setEventFilter([])}
          />
        </Flex>
        <ToggleProvider>
          <EventsPage
            applyFilter={[
              {
                column: "deviceId",
                value: row?.original?.name,
              },
              eventFilter,
            ]}
            isInDrawer={true}
          />
        </ToggleProvider>
      </>
    ),
  };

  const columnsList = [
    {
      Header: "details",
      accessor: "button",
      page: "device_list",
      sliderPopupComponent: (handleClose, row) => (
        <UpdateDeviceLayout
          onClose={handleClose}
          deviceData={row?.original}
          deviceId={row?.original?.id}
        />
      ),
    },
  ];

  const cardGrid = {
    page: "device_list",
    component: (data) => (
      <DeviceCardGrid data={data} deleteDevice={deleteDevice} />
    ),
  };

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  useEffect(() => {
    if (devices?.length > 0) {
      const newDataWithCoordinates = devices.map(({ history, ...item }) => {
        const latitude = item.latitude ? item.latitude.toFixed(5) : "N/A";
        const longitude = item.longitude ? item.longitude.toFixed(5) : "N/A";
        return {
          ...item,
          coordinates: `${latitude}, ${longitude}`,
        };
      });

      setDataWithCoordinates(newDataWithCoordinates);
    }
  }, [devices, devices?.length]);

  return (!devices || devices.length === 0) && isUpdating ? (
    <LoadingSpinner />
  ) : (
    <Box
      bgColor="white"
      padding="15px"
      shadow={"lg"}
      // pt={{ base: "130px", md: "80px", xl: "80px" }}
    >
      <Flex>
        {/* <SearchBar setSearchText={setSearchText} /> */}
        <ToggleViewSwitch
          isListTable={isListTable}
          setIsListTable={setIsListTable}
        />
      </Flex>
      {dataWithCoordinates?.length > 0 ? (
        isMobile ? (
          <FiltersTable
            columnsVariables={columnsDataDevices}
            tableData={dataWithCoordinates}
            pageType={"device_list"}
            statusOptions={statusOptions}
            cardGrid={cardGrid}
          />
        ) : (
          <>
            <Collapse in={isListTable}>
              <FiltersTable
                columnsVariables={columnsDataDevices}
                tableData={dataWithCoordinates}
                searchText={searchText}
                rowClick={rowClick}
                columnsList={columnsList}
                pageType={"device_list"}
                statusOptions={statusOptions}
                hideColumnsOnLoad={{
                  id: false,
                  name: true,
                  location: true,
                  version: true,
                  lastModificationDateTime: true,
                  tags: true,
                  alive: true,
                  latitude: false,
                  longitude: false,
                  coordinates: false,
                  status: true,
                  type: true,
                  history: false,
                }}
              />
            </Collapse>

            <Collapse in={!isListTable}>
              {" "}
              <FiltersTable
                columnsVariables={columnsDataDevices}
                tableData={dataWithCoordinates}
                pageType={"device_list"}
                statusOptions={statusOptions}
                cardGrid={cardGrid}
              />
            </Collapse>
          </>
        )
      ) : (
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          w={"100%"}
          minH={"100vh"}
        >
          <Flex alignItems={"center"}>
            <Text pl="0.5rem" fontSize="4xl" as={"b"}>
              {t("empty_data")}
            </Text>
          </Flex>
        </Flex>
      )}
    </Box>
  );
}
