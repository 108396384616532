import { Flex, Text, IconButton, Input } from "@chakra-ui/react";
import React from "react";

import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTranslation } from "react-i18next";

export default function Pagination(props) {
  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageIndex,
    pageSize,
  } = props;

  const { t } = useTranslation();

  return (
    <Flex justify="space-between" align="center" m="20px">
      <Flex>
        <IconButton
          onClick={() => gotoPage(0)}
          isDisabled={!canPreviousPage}
          icon={<ArrowLeftIcon />}
          aria-label="First Page"
        />
        <IconButton
          onClick={() => previousPage()}
          isDisabled={!canPreviousPage}
          icon={<KeyboardArrowLeftIcon fontSize="large" />}
          aria-label="Previous Page"
        />
        <IconButton
          onClick={() => nextPage()}
          isDisabled={!canNextPage}
          icon={<KeyboardArrowRightIcon fontSize="large" />}
          aria-label="Next Page"
        />
        <IconButton
          onClick={() => gotoPage(pageCount - 1)}
          isDisabled={!canNextPage}
          icon={<ArrowRightIcon />}
          aria-label="Last Page"
        />
      </Flex>
      <Text p={"5px"} pr={"20px"}>
        {t("pagination.page")}{" "}
        <strong>
          {pageIndex + 1} {t("of")} {pageOptions.length}
        </strong>{" "}
      </Text>
      <Flex align="center">
        <Text> {t("pagination.go_to")}</Text>
        <Input
          type="number"
          width={"5px"}
          mr={"20px"}
          ml={"5px"}
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
          style={{ width: "100px", marginLeft: "10px" }}
        />
      </Flex>
      <Flex align="center">
        <Text> {t("pagination.rows")}</Text>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          style={{ marginLeft: "10px" }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </Flex>
    </Flex>
  );
}
