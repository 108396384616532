import { Flex, Spinner, Text } from "@chakra-ui/react"
import React from "react"

const LoadingSpinner = () => {
    return (
        <Flex
      justifyContent={"center"}
      alignItems={"center"}
      w={"100%"}
      minH={"100vh"}
    >
      <Flex alignItems={"center"}>
        <Spinner size={"xl"} />
        <Text pl="0.5rem" fontSize="4xl" as={"b"}>
          Loading...
        </Text>
      </Flex>
    </Flex>
    )
}

export default LoadingSpinner;