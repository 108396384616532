import React from "react";
import { Flex } from "@chakra-ui/react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

const ToggleViewSwitch = ({ isListTable, setIsListTable }) => {
  return (
    <Flex
      px="25px"
      justify="flex-end"
      align="center"
      ml="auto"
      display={{ base: "none", md: "flex" }}
    >
      <Flex>
        <Flex
          _hover={{
            background: "pcr.200",
            color: "secondaryGray.900",
          }}
          background={isListTable ? "pcr.100" : "pcr.200"}
          color={"secondaryGray.900"}
          transition={"0.2s ease-in-out"}
          display="flex"
          alignItems="center"
          w={"100%"}
          p="2"
          cursor="pointer"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          onClick={() => {
            if (isListTable) setIsListTable(!isListTable);
          }}
          borderLeftRadius={"xl"}
        >
          <DashboardIcon />
        </Flex>
        <Flex
          _hover={{
            background: "pcr.200",
            color: "secondaryGray.900",
          }}
          background={isListTable ? "pcr.200" : "pcr.100"}
          color={"secondaryGray.900"}
          transition={"0.2s ease-in-out"}
          display="flex"
          alignItems="center"
          w={"100%"}
          p="2"
          cursor="pointer"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          onClick={() => {
            if (!isListTable) setIsListTable(!isListTable);
          }}
          borderRightRadius={"xl"}
        >
          <FormatListBulletedIcon />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ToggleViewSwitch;
