import React from "react";
import { Button, Flex, IconButton } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AddIcon } from "@chakra-ui/icons";

const FilterActions = ({ handleAddFilter, handleReset }) => {
  const { t } = useTranslation();
  return (
    <Flex mt={"20px"}>
      <IconButton
        bgColor={"pcr.200"}
        _hover={{ bgColor: "pcr.100" }}
        onClick={handleAddFilter}
        icon={<AddIcon />}
        aria-label={t("column_filter.add")}
        mr={2}
      />
      <Button
        bgColor={"pcr.200"}
        _hover={{ bgColor: "pcr.100" }}
        onClick={handleReset}
      >
        {t("column_filter.reset")}
      </Button>
    </Flex>
  );
};

export default FilterActions;
