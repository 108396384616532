import React, { useState, useEffect } from "react";
import { Box, VStack, Flex, Container } from "@chakra-ui/react";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import API from "api/API";
import PermissionsBox from "./PermissionsBox";

export default function PermissionsList({ groupsData }) {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [groupUsers, setGroupUsers] = useState([]);
  const [searchGroup, setSearchGroup] = useState("");
  const [searchUser, setSearchUser] = useState("");

  useEffect(() => {
    if (selectedGroup) {
      API.getGroupOperators(selectedGroup).then((result) => {
        if (result.isSuccess) {
          setGroupUsers(result.data);
        } else {
          console.error("failed get data");
        }
      });
    }
  }, [selectedGroup]);

  const handleGroupClick = (group) => {
    if (group.name === selectedGroup?.name) {
      setSelectedGroup(null);
    } else {
      setSelectedGroup(group);
    }
    setSelectedUser(null);
  };

  const handleUserClick = (user) => {
    if (user.email === selectedUser?.email) {
      setSelectedUser(null);
    } else {
      setSelectedUser(user);
    }
  };

  const filteredGroups = groupsData.filter((group) =>
    group.name.toLowerCase().includes(searchGroup.toLowerCase())
  );

  const filteredUsers = groupUsers.filter((user) =>
    user.firstName.toLowerCase().includes(searchUser.toLowerCase())
  );

  return (
    <Container maxW="container.xl" centerContent>
      <Flex
        flexDirection={{
          base: "column",
          xl: "row",
        }}
        justify="space-between"
        w="100%"
        h="100%"
      >
        <VStack w="100%" spacing={10} mt={"30px"} mb="20px">
          <SearchBar setSearchText={setSearchGroup} />

          <Box
            borderWidth="2px"
            border="1px"
            borderRadius="md"
            overflow="hidden"
            w={{ base: "100%", md: "60%", xl: "60%" }}
            minH="200px"
            overflowY="auto"
            mb="20px"
            mt="20px"
          >
            {filteredGroups.map((group) => (
              <Box
                key={group.name}
                p="4"
                bg={
                  selectedGroup && selectedGroup.name === group.name
                    ? "pcr.200"
                    : "white"
                }
                cursor="pointer"
                onClick={() => handleGroupClick(group)}
              >
                {group.name}
              </Box>
            ))}
          </Box>
          <PermissionsBox selected={selectedGroup} isGroup={true} />
        </VStack>
        <VStack w="100%" spacing={10} mt={"30px"}>
          <SearchBar setSearchText={setSearchUser} />

          <Box
            borderWidth="2px"
            border="1px"
            borderRadius="md"
            overflow="hidden"
            w={{ base: "100%", md: "60%", xl: "60%" }}
            minH="200px"
            overflowY="auto"
            mb="20px"
            mt="20px"
          >
            {filteredUsers.map((user) => (
              <Box
                key={user.email}
                p="4"
                bg={
                  selectedUser && selectedUser.email === user.email
                    ? "pcr.200"
                    : "white"
                }
                cursor="pointer"
                onClick={() => handleUserClick(user)}
              >
                {user.firstName} {user.lastName}
              </Box>
            ))}
          </Box>

          <PermissionsBox selected={selectedUser} isUser={true} />
        </VStack>
      </Flex>
    </Container>
  );
}
