export const columnsData = [
  {
    Header: "name",
    accessor: "firstName",
    type: "text",
  },
  {
    Header: "surname",
    accessor: "lastName",
    type: "text",
  },
  {
    Header: "location",
    accessor: "location",
    type: "text",
  },
  {
    Header: "number",
    accessor: "number",
    type: "text",
  },
  {
    Header: "groups",
    accessor: "groups",
    type: "textList",
  },
  {
    Header: "last_access",
    accessor: "lastAccess",
    type: "text",
  },
  {
    Header: "availability",
    accessor: "availability",
    type: "status",
  },
  {
    Header: "isTicketManager",
    accessor: "isTicketManager",
    type: true,
  },
];
