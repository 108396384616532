/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Collapse,
  Flex,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
// Custom components

import React, { useEffect, useState } from "react";
import OperatorCardGrid from "./components/OperatorCardGrid";
import { useOperatorsStore } from "contexts/stores";
import { useTranslation } from "react-i18next";
import ToggleViewSwitch from "components/switch/ToggleViewSwitch";
import LoadingSpinner from "components/generic/LoadingSpinner";
import FiltersTable from "components/filtersTable/FiltersTable";
import CreateOperator from "./components/CreateOperator";
import { columnsData } from "./variables/columnsData";

export default function OperatorsPage() {
  const [isListTable, setIsListTable] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { operators, isUpdating, fetchOperators } = useOperatorsStore();
  const { t } = useTranslation();

  const statusOptions = [
    { value: 0, label: t(`operators_list.status_list.0`) },
    { value: 1, label: t("operators_list.status_list.1") },
    { value: 2, label: t("operators_list.status_list.2") },
    { value: 3, label: t("operators_list.status_list.3") },
    { value: 4, label: t("operators_list.status_list.4") },
  ];

  const isMobile = useBreakpointValue(
    { base: true, md: false },
    {
      fallback: "md",
    }
  );

  const rowClick = {
    page: "device_list",
    render: (handleClose, row) => (
      <CreateOperator onClose={handleClose} operatorData={row?.original} />
    ),
  };

  const cardGrid = {
    page: "operators_list",
    component: (data) => <OperatorCardGrid data={data} />,
  };

  useEffect(() => {
    fetchOperators();
  }, [fetchOperators]);
  return isUpdating && (!operators || operators?.length === 0) ? (
    <LoadingSpinner />
  ) : (
    <>
      {operators?.length !== 0 ? (
        <Box bgColor="white" padding="15px" shadow={"lg"}>
          <Flex>
            {/* <SearchBar setSearchText={setSearchText} /> */}
            <ToggleViewSwitch
              isListTable={isListTable}
              setIsListTable={setIsListTable}
            />
          </Flex>{" "}
          {isMobile ? (
            <FiltersTable
              columnsVariables={columnsData}
              tableData={operators ? operators : []}
              pageType={"operators_list"}
              statusOptions={statusOptions}
              cardGrid={cardGrid}
            />
          ) : (
            <>
              <Collapse in={isListTable}>
                <FiltersTable
                  columnsVariables={columnsData}
                  tableData={operators ? operators : []}
                  rowClick={rowClick}
                  pageType={"operators_list"}
                  statusOptions={statusOptions}
                  hideColumnsOnLoad={{
                    id: false,
                    enabled: false,
                    username: true,
                    firstName: true,
                    lastName: true,
                    email: true,
                    lastAccess: true,
                    number: false,
                    location: true,
                    availability: true, //if false, no rowColor?
                    credentials: false,
                    groups: false,
                  }}
                />
              </Collapse>
              <Collapse in={!isListTable}>
                {" "}
                <FiltersTable
                  columnsVariables={columnsData}
                  tableData={operators ? operators : []}
                  pageType={"operators_list"}
                  statusOptions={statusOptions}
                  cardGrid={cardGrid}
                />
              </Collapse>
            </>
          )}
        </Box>
      ) : (
        <Box bgColor="white" padding="15px" shadow={"lg"}>
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            w={"100%"}
            minH={"100vh"}
          >
            <Flex alignItems={"center"}>
              <Text pl="0.5rem" fontSize="4xl" as={"b"}>
                {t("empty_data")}
              </Text>
            </Flex>
          </Flex>
        </Box>
      )}{" "}
    </>
  );
}
