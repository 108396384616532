import React from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

const SliderPopup = ({ isOpen, onClose, children }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isKeyboardOpen = useDetectKeyboardOpen();

  return (
    <Drawer
      isOpen={isOpen}
      placement={isMobile ? "top" : "bottom"}
      onClose={onClose}
      autoFocus={!isMobile}
      allowPinchZoom={true}
    >
      <DrawerOverlay />
      <DrawerContent height={isMobile && isKeyboardOpen ? "50vh" : "90vh"}>
        <DrawerBody
          overflow={isMobile ? "auto" : "visible"}
          overflowY="auto"
          height={isMobile && isKeyboardOpen ? "50vh" : "90vh"}
        >
          <Flex justifyContent={"center"} display={"flex"}>
            <Button
              onClick={onClose}
              mb={1}
              bgColor={"pcr.200"}
              _hover={{ bgColor: "pcr.100" }}
            >
              <ClearOutlinedIcon />
            </Button>
          </Flex>
          {children}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default SliderPopup;
