import { Flex, Input } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";

const FilterValueInput = ({
  selectedColumn,
  inputValue,
  setInputValue,
  handleFilterChange,
  handleDateFilterChange,
  customStyles,
  booleanOptions,
  statusOptions,
  inputDateFromValue,
  inputDateToValue,
}) => {
  const { t } = useTranslation();

  if (selectedColumn && typeof selectedColumn.type === "boolean") {
    return (
      <ReactSelect
        options={booleanOptions}
        onChange={(option) => {
          setInputValue(option.value);
          handleFilterChange(option.value);
        }}
        value={
          inputValue
            ? {
                value: inputValue,
                label:
                  inputValue === "true"
                    ? t("column_filter.value_enabled")
                    : t("column_filter.value_no_enabled"),
              }
            : null
        }
        placeholder={t("column_filter.filter")}
        styles={customStyles}
      />
    );
  } else if (selectedColumn && selectedColumn.type === "status") {
    return (
      <ReactSelect
        options={statusOptions}
        onChange={(option) => {
          setInputValue(option.value);
          handleFilterChange(option.value);
        }}
        value={statusOptions.find((opt) => opt.value === inputValue) || null}
        placeholder={t("column_filter.select_status")}
        styles={customStyles}
      />
    );
  } else if (selectedColumn && selectedColumn.type === "date") {
    return (
      <Flex maxW={"180px"} direction={"column"}>
        {t("date.from")}:{" "}
        <Input
          type={"datetime-local"}
          value={inputDateFromValue}
          focusBorderColor="black"
          backgroundColor={"#EEEEEE"}
          placeholder={t("column_filter.filter")}
          onChange={(e) => handleDateFilterChange(e.target.value, "from")}
          width={"100%"}
          maxWidth={"200px"}
          mb={1}
        />
        {t("date.to")}:{" "}
        <Input
          type={"datetime-local"}
          value={inputDateToValue}
          focusBorderColor="black"
          backgroundColor={"#EEEEEE"}
          placeholder={t("column_filter.filter")}
          onChange={(e) => handleDateFilterChange(e.target.value, "to")}
          width={"100%"}
          maxWidth={"200px"}
        />
      </Flex>
    );
  } else {
    return (
      <Input
        value={inputValue}
        focusBorderColor="black"
        backgroundColor={"#EEEEEE"}
        placeholder={t("column_filter.filter")}
        onChange={(e) => handleFilterChange(e.target.value)}
        width={"100%"}
        maxWidth={"200px"}
      />
    );
  }
};

export default FilterValueInput;
