import React, { useEffect, useState } from "react";
import { Box, Text, Spinner, Button, Input } from "@chakra-ui/react";
import { useOperatorsStore } from "contexts/stores";
import { useKeycloak } from "contexts/stores";
import { useTranslation } from "react-i18next"; // Assicurati di avere `react-i18next` installato
import API from "api/API";
import FiltersTable from "components/filtersTable/FiltersTable";
import { enqueueSnackbar } from "notistack";

export const columnsData = [
  {
    Header: "date",
    accessor: "date",
    type: "date",
  },
  {
    Header: "timezone",
    accessor: "timezone",
    type: "text",
  },
  {
    Header: "level",
    accessor: "level",
    type: "status",
  },
  {
    Header: "message",
    accessor: "message",
    type: "text",
  },
];

const parseLogs = (logData) => {
  if (typeof logData !== "string") {
    console.error("Il contenuto dei log non è una stringa.");
    return [];
  }

  const logLines = logData.replace(/\r/g, "").split("\n").filter(Boolean);

  return logLines
    .map((log) => {
      const regex =
        /^(?<date>\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{3})\s(?<timezone>[+-]\d{2}:\d{2})\s\[(?<level>[A-Z]+)]\s(?<message>.+)$/;

      const match = log.match(regex);

      if (match && match.groups) {
        return {
          date: match.groups.date,
          timezone: match.groups.timezone,
          level: getStatusLevel(match.groups.level),
          message: match.groups.message,
        };
      }
      return null;
    })
    .filter(Boolean);
};

const getStatusLevel = (level) => {
  switch (level) {
    case "INF":
      return 0;
    case "ERR":
      return 2;
    case "WARN":
      return 1;
    default:
      return null;
  }
};

const LogViewer = () => {
  const [logEntries, setLogEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState({});
  const { operators } = useOperatorsStore();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation(); // Funzione di traduzione
  const [inputDateValue, setInputDateValue] = useState("");

  const statusOptions = [
    { value: 0, label: t(`logs_list.status_list.0`) },
    { value: 1, label: t("logs_list.status_list.1") },
    { value: 2, label: t("logs_list.status_list.2") },
  ];

  useEffect(() => {
    // Controlla se l'utente corrente ha i permessi per visualizzare i log
    const user = operators.find((o) => o.id === keycloak.subject && o?.logs);
    if (user) {
      API.getAllLogs()
        .then((result) => {
          setLogs(result.data);
        })
        .catch((error) => {
          console.error("Errore nel recupero dei log:", error);
          enqueueSnackbar(t("logs_list.error"), {
            variant: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [keycloak.subject]);

  // Analisi dei log appena arrivano
  useEffect(() => {
    if (logs && logs.content) {
      const formattedLogs = parseLogs(logs.content);

      setLogEntries(formattedLogs);
    }
  }, [logs]);

  return (
    <Box p={5}>
      <Text fontSize="2xl" fontWeight="bold" mb={5}>
        {t("settings.view_logs")}
      </Text>
      <Box mb={4}>
        {t("logs_list.date")}:{" "}
        <Input
          type={"date"}
          value={inputDateValue}
          focusBorderColor="black"
          backgroundColor={"#EEEEEE"}
          placeholder={t("column_filter.filter")}
          onChange={(e) => {
            setInputDateValue(e.target.value);
          }}
          width={"100%"}
          maxWidth={"200px"}
          mb={1}
        />
        <Button
          onClick={() => {
            API.getAllLogs(inputDateValue)
              .then((result) => {
                if (result.data?.fileName === undefined) {
                  enqueueSnackbar(t("logs_list.empty"), {
                    variant: "warning",
                  });
                } else setLogs(result.data);
              })
              .catch((error) => {
                console.error("Errore nel recupero dei log:", error);
                enqueueSnackbar(t("logs_list.error"), {
                  variant: "error",
                });
              });
          }}
          bgColor={"pcr.200"}
          _hover={{ bgColor: "pcr.100" }}
          ml="5px"
        >
          {t("logs_list.retrieve_logs")}
        </Button>
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Spinner size="xl" color="green.500" />
          <Text ml={3}>{t("logs_list.loading_logs")}</Text>
        </Box>
      ) : (
        <FiltersTable
          tableData={logEntries}
          pageType={"logs_list"}
          columnsVariables={columnsData}
          statusOptions={statusOptions}
        />
      )}
    </Box>
  );
};

export default LogViewer;
