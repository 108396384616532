import React, { useState, useEffect } from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { useTranslation } from "react-i18next";
import API from "api/API";

const inputBgColor = "#EEEEEE";
const nTags = 30;

const CreateDeviceTag = ({ control, errors, isReadOnly, deviceTags }) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await API.getAllTags();
        if (Array.isArray(response.data)) {
          setTags(
            response.data
              .map((item) => ({ value: item.id, label: item.name }))
              .slice(-nTags)
          );
        } else {
          setTags([]);
          setError(new Error("Invalid data format"));
        }
      } catch (err) {
        setError(err);
        setTags([]);
      } finally {
        setLoading(false);
      }
    };

    fetchTags();
  }, []);

  useEffect(() => {
    setTagOptions(
      tags.filter(
        (tag) => !deviceTags?.some((deviceTag) => deviceTag.label === tag.label)
      )
    );
  }, [tags]);

  return (
    <FormControl p={"0px"} isInvalid={errors.tags}>
      <Grid templateColumns={{ base: "1fr", md: "150px 1fr" }} gap={1} mb={4}>
        <Flex alignItems="center">
          <FormLabel>{t("create_device_form.tags")}</FormLabel>
        </Flex>
        <Flex direction={"column"}>
          <Controller
            name="tags"
            control={control}
            // rules={{ required: t("create_device_form.tags_required") }}
            render={({ field }) => (
              <CreatableSelect
                isDisabled={isReadOnly}
                {...field}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    marginLeft: 8,
                    backgroundColor: isReadOnly ? inputBgColor : "white",
                    borderColor: isReadOnly ? "" : inputBgColor,
                  }),
                }}
                options={tagOptions}
                isMulti
                menuPlacement="top"
                onChange={(selected) => {
                  field.onChange(selected);
                }}
                placeholder={t("create_device_form.tags")}
              />
            )}
          />
        </Flex>
      </Grid>
      <FormErrorMessage>{errors.tags && errors.tags.message}</FormErrorMessage>
    </FormControl>
  );
};

export default CreateDeviceTag;
