import React, { useEffect } from "react";
import { useMeasuresStore } from "contexts/stores";
import { Box, Flex, Text } from "@chakra-ui/react";
import FiltersTable from "components/filtersTable/FiltersTable";
import LoadingSpinner from "components/generic/LoadingSpinner";
import { useTranslation } from "react-i18next";
import { columnsRawData } from "./variables/columnsData";

const MeasuresTable = () => {
  const {
    fetchMeasurePage,
    measuresCount,
    isUpdating,
    filters,
    fetchMeasuresCount,
    setFilters,
  } = useMeasuresStore(); // Fetch function
  const { t } = useTranslation();

  const columnsList = [];
  const columnsVariables = columnsRawData;

  useEffect(() => {
    setFilters([]);
    fetchMeasuresCount();
  }, []);

  // Define the fetchPage function
  const fetchPageData = async (
    pageIndex,
    itemsPerPage,
    globalFilter,
    sortBy
  ) => {
    try {
      const result = await fetchMeasurePage(
        pageIndex,
        itemsPerPage,
        globalFilter,
        sortBy,
        filters
      );
      return {
        data: result.data,
        totalItems: result.totalItems,
      };
    } catch (error) {
      console.error("Error fetching page data:", error);
      return { data: [], totalItems: 0 };
    }
  };

  // Define pagination object
  const pagination = {
    fetchPage: fetchPageData,
  };

  return measuresCount === 0 && isUpdating ? (
    <LoadingSpinner />
  ) : measuresCount > 0 ? (
    <Box bgColor="white" padding="15px" shadow="lg">
      <FiltersTable
        columnsList={columnsList}
        columnsVariables={columnsVariables}
        pagination={pagination}
        pageType={"raw_data_list"}
        hideColumnsOnLoad={{
          timestamp: true,
          device: true,
          variable: true,
          value: true,
        }}
      />
    </Box>
  ) : (
    <>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        w={"100%"}
        minH={"100vh"}
      >
        <Flex alignItems={"center"}>
          <Text pl="0.5rem" fontSize="4xl" as={"b"}>
            {t("empty_data")}
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

export default MeasuresTable;
