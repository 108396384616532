import React from "react";
import { Box, SimpleGrid, useBreakpointValue } from "@chakra-ui/react";
import VariableHistory from "../VariableHistory/VariableHistory";
import SeverityForm from "./SeverityForm";

const UpdateVariableLayout = ({ onClose, variableData, variableId }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box height="calc(100vh - 100px)">
      <SimpleGrid columns={isMobile ? 1 : 2} spacing={4} height="100%">
        <Box
          p={4}
          borderRadius="md"
          boxShadow="md"
          overflowY="auto"
          height={isMobile ? "100%" : "100vh"}
        >
          <SeverityForm variableData={variableData} onClose={onClose} />
        </Box>
        <Box
          p={4}
          borderRadius="md"
          boxShadow="md"
          overflowY={"hidden"}
          height="100%"
        >
          <VariableHistory onClose={onClose} variableId={variableId} />
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default UpdateVariableLayout;
